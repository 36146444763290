import React from "react";
import { Col, Container, Row } from "reactstrap";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import MasterLayout from "../../../layout/masterlayout";

const StoryHappyfaniaGervasKimaro = (props: any) => {
  return (
    <>
      <section className="bg-story-detail story-detail-banner pt-50px">
        <Container>
          <Row>
            <Col md="4" className="text-md-center">
              <img
                src={toAbsoluteUrl(
                  "/media/images/thumb_HappyfaniaGervasKimaro.png"
                )}
                alt=""
              />
            </Col>
            <Col md="7">
              <div>
                <h1 className="mb-10px font-38">
                  Through the company, I have managed to afford my children’s
                  school fees and better my life.
                </h1>
                <p className="mb-10px">
                  I now have market assurance with stable prices which have
                  stabilised my capital
                </p>
                <p className="mb-10px">10 Jan, 2023</p>
                <div>
                  <h3 className="mb-10px font-20">
                    Happyfania Gervas Kimaro -{" "}
                    <span className="inter font-16 fw-400">Banana Farmer</span>
                  </h3>
                </div>
                <p className="mb-10px">Mamsera - Kilimanjaro</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* story content */}
      <section className="pt-50px pb-50px">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p className="fw-600">
                Happyfania is a farmer and entrepreneur from Mamsera, in
                Kilimanjaro region. Her journey with EA Foods started in May
                2021 when she served us with 15 banana bundles once a week.
              </p>
              <p>
                This was a transition to her as she used to supply fresh
                bananas, mainly the Malindi variety to the local market in
                Mamsera.
              </p>
              <p>
                One of the biggest challenges she faced were price fluctuation,
                untimely to no payment of her fresh produce which made it hard
                for her to support her family financially.
              </p>
              <p>
                She joined us aboard in May 2021 after seeing our signboard
                Sokochap in Mamsera and started with 15 banana bundles once per
                week and grew to 20 bundles two times a week and now she
                supplies us with 20 bundles 5 times a week i.e 100 bundles a
                week.
              </p>
              <p className="fw-600">
                “Life was very hard before the coming of this company, I now
                have market assurance with stable prices which have stabilised
                my capital. Through the company, I have managed to afford my
                children’s school fees and better my life”
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding pt-0">
        <img
          src={toAbsoluteUrl("/media/images/happyfaniaGervasKimaro.jpg")}
          alt=""
          className="mb-30px img-fluid w-100"
        />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p>
                Happyfania says her life has changed for the better since
                working with EA Foods, as she has employed 2 people to help her
                with the banana processing activities, opened up a soft drinks
                and beer grocery shop and looks forward to opening another shop
                in the coming days to occupy her during the weekends.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(StoryHappyfaniaGervasKimaro);
