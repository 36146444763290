import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { Col, Container, Row } from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { BLOG_SINGLE_POSTS } from "../../../_eaFruitsCms/https/ApiRoutes";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";

const BlogDetail = (props: any) => {
  const navigate = useNavigate();

  const postId = localStorage.getItem("postId");
  const currentURL = window.location.href;

  let { blogSlug } = useParams();

  const [singlePost, setSinglePosts] = useState<any>([]);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);

  useEffect(() => {
    fetchPostsDetail().then();
  }, [singlePost.description]);

  useEffect(() => {
    goToTop(setDisplayLoader);
  }, []);

  const fetchPostsDetail = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      BLOG_SINGLE_POSTS + `/${blogSlug}`,
      navigate,
      false
    );
    setSinglePosts(response);
    setDisplayLoader(false);
  };

  return (
    <>
      <CustomHelmet
        title={`${singlePost && singlePost.title} | EA Foods`}
        metaDescription={`${singlePost && singlePost.title} | EA Foods`}
        imagePath={singlePost.cover_image}
      />
      {displayLoader && <CustomLoader />}
      <section className="section-padding blog-banner d-flex align-items-center">
        <img
          src={
            singlePost.cover_image
              ? singlePost.cover_image
              : toAbsoluteUrl("/media/images/blogHeroBanner.jpg")
          }
          className="blogheroimg"
        />
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" xl="9">
              <h1 className="text-center text-white fw-700 position-relative blog-banner-title">
                {singlePost.title}
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding pb-0">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" xl="8">
              <div className="blog-detail-desc">
                <p className="font-20 fw-500 mb-30">{singlePost.short_desc}</p>
                <p className="mb-20">
                  <div
                    dangerouslySetInnerHTML={{ __html: singlePost.description }}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding pt-0">
        <Container>
          <Row className="mb-4 justify-content-center">
            <Col md="10" lg="9" xl="8">
              <hr />
              <Row>
                <Col sm="6" className="mb-3 mb-md-0">
                  <span className="fw-600">
                    {singlePost.author && singlePost.author.name}
                  </span>
                </Col>
                <Col className="text-md-end" sm="6">
                  <div className="social d-flex align-items-center justify-content-sm-end">
                    <span className="fw-600">Share this on</span>
                    <ul className="text-white list-unstyled mb-0 d-inline-flex ms-3">
                      <li className="me-3">
                        <FacebookShareButton
                          // hashtag={`${
                          //   singlePost && singlePost.title
                          // } | EA Foods`}
                          quote={`${singlePost && singlePost.title} | EA Foods`}
                          url={currentURL}
                          title={`${
                            singlePost && singlePost.story_title
                          } | EA Foods`}
                        >
                          <KTSVG
                            path="/media/icons/ic_facebook.svg"
                            className="ic text-primary"
                          />
                        </FacebookShareButton>
                      </li>
                      {/* <li className="me-3">
                      <InstagramShareButton
                        // title={`${singlePost && singlePost.title} | EA Foods`}
                        // description={`${
                        //   singlePost && singlePost.title
                        // } | EA Foods`}
                        url="http://eaf-cms.yecor.com/"
                      >
                        <KTSVG
                          path="/media/icons/ic_insta.svg"
                          className="ic text-primary"
                        />
                      </InstagramShareButton>
                    </li> */}
                      <li className="me-3">
                        <TwitterShareButton
                          title={`${singlePost && singlePost.title} | EA Foods`}
                          // via={`${singlePost && singlePost.title} | EA Foods`}
                          // hashtags= {`${singlePost && singlePost.title} | EA Foods`}
                          // url="http://eaf-cms.yecor.com/"
                          url={currentURL}
                        >
                          <KTSVG
                            path="/media/icons/ic_twitter.svg"
                            className="ic text-primary"
                          />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          title={`${singlePost && singlePost.title} | EA Foods`}
                          summary={`${
                            singlePost && singlePost.title
                          } | EA Foods`}
                          url={currentURL}
                        >
                          <KTSVG
                            path="/media/icons/ic_linkedin.svg"
                            className="ic text-primary"
                          />
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(BlogDetail);
