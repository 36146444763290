import React from "react";
import { Col, Container, Row } from "reactstrap";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import MasterLayout from "../../../layout/masterlayout";

const StoryJoyceAssenga = (props: any) => {
  return (
    <>
      <section className="bg-story-detail story-detail-banner pt-50px">
        <Container>
          <Row>
            <Col md="4" className="text-md-center">
              <img
                src={toAbsoluteUrl("/media/images/thumb_JoyceAssenga.png")}
                alt=""
              />
            </Col>
            <Col md="7">
              <div>
                <h1 className="mb-10px font-38">
                  EA Foods provides me with timely payment of all my produce at
                  once!
                </h1>
                <p className="mb-10px">
                  I wish for them to expand more and more!.
                </p>
                <p className="mb-10px">3 Jan. 20233</p>
                <div>
                  <h3 className="mb-10px font-20">
                    Joyce Assenga -{" "}
                    <span className="inter font-16 fw-400">Banana Farmer</span>
                  </h3>
                </div>
                <p className="mb-10px">Mengwe - Kilimanjaro</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* story content */}
      <section className="pt-50px pb-50px">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p>
                Here’s another beautiful story from Joyce Assenga, one of our
                banana farmers from Mengwe who has been with us for over 2 years
                now. Apart from being one of our registered farmers, Joyce is
                also an entrepreneur and indulges in other economic activities
                as well such as animal keeping.
              </p>

              <p>
                Her story is quite interesting, she used to sell fresh bananas
                in retail to the local markets before joining us, later on, she
                expanded her business and started selling bananas in bulk orders
                to EA Foods which elevated her from just 5 trunks to up to 20
                trunks per single order.
              </p>

              <p className="fw-600 mb-0">
                “Honestly, joining EA Foods has helped me so much, they have
                given me market assurance . When I have 20 bananas, I am sure
                that I will sell the whole consignment unlike the local markets
                where I would have sold just 5 out of 10 trunks. I have been
                able to afford my 4 children’s school fees. I wish for them to
                expand more and more so as to give room to more farmers to join
                as well”.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding pt-0">
        <img
          src={toAbsoluteUrl("/media/images/joyceAssenga.jpg")}
          alt=""
          className="mb-30px img-fluid w-100"
        />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p className="mb-0">
                Joyce’s endeavour to better her life is just getting better each
                day, she has currently employed assistants to help her with the
                business and her order delivery has increased from 20 trunks in
                a week to 25 trunks after every 3 days!
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(StoryJoyceAssenga);
