import moment from "moment-timezone"
import {toast} from "react-toastify"

export const GLOBAL_DATE_FORMAT = process.env.REACT_APP_BASE_DATE_FORMAT // GLOBAL DATE FORMAT

export const MOBILE_MAX_LENGTH : any  = process.env.REACT_APP_MOBILE_MAX_LENGTH

export const CURRENT_ENVIRONMENT = process.env.REACT_APP_IS_PRODUCTION && process.env.REACT_APP_IS_PRODUCTION === 'true'

export const dateFormat = (date: any, dateFormat?: any) => {
    let mainDate = {
        // displayDate: getTimeZone === null ? moment(date).format(GLOBAL_DATE_FORMAT) : moment(date).tz(getTimeZone).format(GLOBAL_DATE_FORMAT),
        displayDate: moment(date).format(GLOBAL_DATE_FORMAT),
        // apiDate: moment(date).format('YYYY-MM-DD'),
        apiDate: moment(date).format('MMM DD,YYYY'),
    }

    if (dateFormat) {
        // mainDate.displayDate = getTimeZone === null ? moment(date).format(dateFormat) : moment(date).tz(getTimeZone).format(dateFormat)
        mainDate.displayDate = moment(date).format(dateFormat)
    }

    return mainDate
}

// number format
export const numberFormat = (price: any) => {
    let format = 'en-UK'

    return {
        displayFormat: price && new Intl.NumberFormat(format).format(price),
        apiFormat: price
    }
}

export const goToTop = (setDisplayLoader ?: any) => {
    setDisplayLoader && setDisplayLoader(true)
    // setTimeout(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    // }, 1000)
}

//Developed By Pinak Mehta to display success toast message
export const successToast = (message: any) => {
    toast(message, {type: "success", icon: true})
}

//Developed By Pinak Mehta to display error toast message
export const errorToast = (message: any) => {
    toast(message, {type: "error", icon: true})
}

//Developed By: Pinak Mehta to find next offset to pass into API
export const nextOffset = (next: string) => {
    const queryParams = new URLSearchParams(next)
    return queryParams.get('offset')
}

export const requestHeaders = (token?: boolean, multipart?: boolean) => {
    const accessToken = localStorage.getItem('token')

    const header: any = {
        headers: {
            'DEVICE-TYPE': 'Web'
        },
    }

    if (multipart) {
        header.headers['Content-Type'] = 'multipart/form-data'
    }

    if (token) {
        header.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return header
}
