// validations

export const validateNumberField = (number: any) => {
  let numberParse = parseInt(number);
  const numberRegEx = /^[0-9]*$/;
  return numberRegEx.test(String(numberParse).toLowerCase());
};

export const validateEmail = (email: any) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  } else {
    return false;
  }
};
