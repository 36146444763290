import React from "react";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";

const NoRecords = () => {
  return (
    <>
      <div className="text-center mb-5 ">
        <img
          src={toAbsoluteUrl("/media/images/error_graphic_one.png")}
          alt="Eafoods"
        />
        <p className="fw-600">No records found !</p>
      </div>
    </>
  );
};
export default NoRecords;
