import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Container,
  Row,
} from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { metaImage, toAbsoluteUrl } from "../../../helper/AssetHelpers";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";

const About = (props: any) => {
  const [open, setOpen] = useState("1");
  const [accordionImage, setAccordionImage] = useState<string>("about1");
  const [imageLoad, setImageLoad] = useState<boolean>(false);

  useEffect(() => {
    goToTop();
  }, []);

  const toggle = (id: any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const showAccordionImage = (accordion: number) => {
    setAccordionImage(`about${accordion}`);
  };

  return (
    <>
      <CustomHelmet
        title={`About Us | EA Foods`}
        metaDescription={`We aggregate demand and deliver wide range of fresh and exotic produce directly from farms to stores of B2B customers.`}
        imagePath={metaImage}
      />
      {/* <section className="about-hero-banner text-center">
          <Container className="h-100" fluid="xl">
            <div className="position-relative h-100 pt-50px">
              <Row className="align-items-start text-center justify-content-center h-100">
                <Col lg={10} xl={8}>
                  <h1 className="herotitle herotitlecenter">About EA Foods</h1>
                  <p>
                    We are the leading data and Tech Driven Food Distribution
                    Company in Tanzania aiming at boosting access to digital
                    commerce for businesses in Africa through providing solutions
                    that help solve market inefficiencies to improve the lives of
                    smallholder farmers by connecting them directly to the
                    markets.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <img
              src={toAbsoluteUrl("/media/illustrations/aboutHeroBanner.svg")}
              alt=""
                    className="img-fluid"
                  />
                  <div className="ms-3 ps-1">
                    <h3 className="fw-700 mb-10px">Our Mission</h3>
                    <p className="mb-0">
                      Improving market access for farmers by modernising supply
                      chain and demand logistics.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-50px">
                  <img
                    src={toAbsoluteUrl("/media/illustrations/icon_vision.svg")}
                    alt=""
                    className="img-fluid"
          />
                  <div className="ms-3 ps-1">
                    <h3 className="fw-700 mb-10px">Our Vision</h3>
                    <p className="mb-0">Africa’s largest food distributor.</p>
                  </div>
                </div>
              </Col>
              <Col lg={5} xl={5} className="offset-xl-1">
                <img
                  src={toAbsoluteUrl("/media/images/about.svg")}
                  alt=""
                  className="img-fluid mt-5 mt-md-0"
                />
            </section> */}
      <section
        className="hero-banner position-relative"
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            "/media/images/heroabout.jpg"
          )})`,
        }}
      >
        <div className="hero-overlay position-absolute"></div>
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col xs={12} md={7} lg={6}>
                <div className="py-4">
                  <h1 className="herotitle">About EA Foods</h1>
                  <p className="mb-0 font-20">
                    We are the leading data and Tech Driven Food Distribution
                    Company in Tanzania aiming at boosting access to digital
                    commerce for businesses in Africa through providing
                    solutions that help solve market inefficiencies to improve
                    the lives of smallholder farmers by connecting them directly
                    to the markets.
                  </p>
                </div>
                <img
                  src={toAbsoluteUrl("/media/illustrations/heroBgVector.svg")}
                  alt=""
                  className="floating animated-shape"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <img
          src={toAbsoluteUrl("/media/images/heroAboutM.jpg")}
          alt=""
          className="img-fluid hero-responsive d-block d-md-none"
        />
      </section>

      {/* Our Mission and Our Vision */}
      <section className="section-padding">
        <Container fluid="xl">
          <Row className="align-items-center justify-content-center">
            <Col lg={6} xl={6}>
              <div className="d-flex align-items-center">
                <img
                  src={toAbsoluteUrl("/media/illustrations/icon_mission.svg")}
                  alt=""
                  className="img-fluid"
                />
                <div className="ms-3 ps-1">
                  <h3 className="fw-700 mb-10px">Our Mission</h3>
                  <p className="mb-0">
                    Improving market access for farmers by modernising supply
                    chain and demand logistics.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-50px">
                <img
                  src={toAbsoluteUrl("/media/illustrations/icon_vision.svg")}
                  alt=""
                  className="img-fluid"
                />
                <div className="ms-3 ps-1">
                  <h3 className="fw-700 mb-10px">Our Vision</h3>
                  <p className="mb-0">Africa’s largest food distributor.</p>
                </div>
              </div>
            </Col>
            <Col lg={5} xl={5} className="offset-xl-1">
              <img
                src={toAbsoluteUrl("/media/images/about.svg")}
                alt=""
                className="img-fluid mt-5 mt-md-0"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* why choose eafruits section start */}
      <section className="section-padding pt-0">
        <SectionHeading sectionTitle="Why Choose EA Foods?" />
        <Container fluid="xl">
          <div className="pt-50px">
            <Row className="leftImageContent">
              <Col lg={6} xl={6}>
                <div className="primary-shadow left-shadow rounded">
                  <div className="position-relative flex-grow-1">
                    <img
                      src={toAbsoluteUrl(`/media/images/${accordionImage}.jpg`)}
                      srcSet={toAbsoluteUrl(
                        `/media/images/${accordionImage}@2x.jpg 2x`
                      )}
                      className="rounded img-fluid why-choose-img"
                      alt=""
                      onLoad={() => {
                        setImageLoad(true);
                      }}
                    />
                    {!imageLoad && <span className="skeleton-box"></span>}
                  </div>
                </div>
              </Col>
              <Col lg={6} xl={6}>
                <Accordion
                  open={open}
                  //@ts-ignore
                  toggle={toggle}
                  flush
                  className="ea-accordion"
                >
                  <AccordionItem onClick={() => showAccordionImage(1)}>
                    <AccordionHeader targetId="1">
                      No Delivery Fees, EVER!
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <p>
                        Customers never have to worry about delivery fees, all
                        fresh produce is delivered on wheels through a carefully
                        planned network of distribution trucks 7 days of the
                        week for free!.
                      </p>
                      <p>
                        It does not matter where you are, our trucks will reach
                        you and release you from the delivery stress.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem onClick={() => showAccordionImage(2)}>
                    <AccordionHeader targetId="2">
                      Great Food Processing and Storage Solutions
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      <p>
                        Ensuring food safety and sustainability using advanced
                        processing and cold storage facilities to ensure maximum
                        flavour and freshness.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem onClick={() => showAccordionImage(3)}>
                    <AccordionHeader targetId="3">
                      Elevating the supply chain to new heights
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      <p>
                        Sign up today on SokoChap and get to experience the full
                        advantages of it such as pre ordering.
                        <br />
                        Available now on Google Play and iOS
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem onClick={() => showAccordionImage(4)}>
                    <AccordionHeader targetId="4">
                      Your Healthy Business Partners
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      <p>
                        There’s no limit, Literally of the varieties of fresh
                        produce to cater all your kitchen and business needs
                        available at EA Foods! And the good thing is you never
                        have to go back to the market, Ever Again!
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem onClick={() => showAccordionImage(5)}>
                    <AccordionHeader targetId="5">
                      Great Customer Service!
                    </AccordionHeader>
                    <AccordionBody accordionId="5">
                      <p>
                        Keep in touch with us for orders, challenges or comments
                        anytime, anywhere!.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem onClick={() => showAccordionImage(6)}>
                    <AccordionHeader targetId="6">
                      AgriTech Solutions
                    </AccordionHeader>
                    <AccordionBody accordionId="6">
                      <p>
                        We develop agritech solutions across the agricultural
                        value chain such as training, production, storage and
                        distribution to boost up market access by adding value
                        to the produce.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* why choose eafruits section end */}

      {/* why choose eafruits section end */}
      <section className="section-padding pt-0">
        <SectionHeading sectionTitle="Important Milestones in Our Growth" />
        <Container fluid="xl" className="pt-50px mt-4">
          <Row className="g-0 justify-content-center flow-row">
            <Col className="flow-div first-div bg-light-primary founded">
              <div>
                <h6 className="text-primary fw-700 font-20">2013</h6>
                <p>Year</p>
                <p>Founded</p>
              </div>
            </Col>
            <Col className="flow-div bg-light-danger ea_customers">
              <div>
                <h6 className="text-danger fw-700 font-20">7,000+</h6>
                <p>EA Foods</p>
                <p>Customers</p>
              </div>
            </Col>
            <Col className="flow-div bg-light-success famer_saved">
              <div>
                <h6 className="text-success fw-700 font-20">10,000+</h6>
                <p>Farmers</p>
                <p>Saved</p>
              </div>
            </Col>
            <Col className="flow-div bg-light-primary app">
              <div>
                <h6 className="text-primary fw-700 font-20">4,000+</h6>
                <p>Deliveries</p>
                <p>per Day</p>
              </div>
            </Col>
            <Col className="flow-div bg-light-danger employee">
              <div>
                <h6 className="text-danger fw-700 font-20">200+</h6>
                <p>EA Foods </p>
                <p>Employess+</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(About);
