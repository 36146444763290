import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

type Props = {
  heroTitle?: string;
  heroDesc?: string;
  buttonText?: string;
  className?: string;
  path?: any;
};

const StoryHeader: React.FC<Props> = ({ heroTitle, heroDesc }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <section className="section-padding bg-light">
        <div className="title text-center">
          <Container>
            <Row>
              <Col lg={8} className="mx-auto">
                <h1 className="herotitle herotitlecenter">{heroTitle}</h1>
                <p className="mb-0">{heroDesc}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* nav tabs start */}
      <section className="bg-light">
        <Container>
          <Row>
            <Col>
              <div className="d-none d-md-block">
                <Nav className="nav-light" horizontal="center">
                  <NavItem>
                    <NavLink
                      href="javascript:void(0)"
                      onClick={() => navigate("/success-stories")}
                      className={
                        `nav-link` +
                        (location.pathname === "/success-stories" && " active")
                      }
                    >
                      All Stories
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate("/success-stories/farmer-stories")
                      }
                      className={
                        `nav-link` +
                        (location.pathname ===
                          "/success-stories/farmer-stories" && " active")
                      }
                    >
                      Farmer Stories
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate("/success-stories/customer-stories")
                      }
                      className={
                        `nav-link` +
                        (location.pathname ===
                          "/success-stories/customer-stories" && " active")
                      }
                    >
                      Customer Stories
                    </NavLink>
                  </NavItem>
                  {/*<NavItem>*/}
                  {/*    <NavLink*/}
                  {/*        href="javascript:void(0)"*/}
                  {/*        onClick={() =>*/}
                  {/*            navigate("/success-stories/employee-stories")*/}
                  {/*        }*/}
                  {/*        className={*/}
                  {/*            `nav-link` +*/}
                  {/*            (location.pathname ===*/}
                  {/*                "/success-stories/employee-stories" && " active")*/}
                  {/*        }*/}
                  {/*    >*/}
                  {/*        Employee Stories*/}
                  {/*    </NavLink>*/}
                  {/*</NavItem>*/}
                </Nav>
              </div>
              {/* mobile menu */}
              <div className="d-block d-md-none my-3">
                <UncontrolledDropdown>
                  <DropdownToggle nav caret>
                    {location.pathname === "/success-stories/farmer-stories"
                      ? "Farmer Stories"
                      : location.pathname ===
                        "/success-stories/customer-stories"
                      ? "Customer Stories"
                      : location.pathname === "/success-stories"
                      ? "All Stories"
                      : location.pathname ===
                          "/success-stories/employee-stories" &&
                        "Employee Stories"}
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <DropdownItem
                      href="/success-stories"
                      className={
                        `dropdown-item` +
                        (location.pathname === "/success-stories" && " active")
                      }
                    >
                      All Stories
                    </DropdownItem>
                    <DropdownItem
                      href="/success-stories/farmer-stories"
                      className={
                        `dropdown-item` +
                        (location.pathname ===
                          "/success-stories/farmer-stories" && " active")
                      }
                    >
                      Farmer Stories
                    </DropdownItem>
                    <DropdownItem
                      href="/success-stories/customer-stories"
                      className={
                        `dropdown-item` +
                        (location.pathname ===
                          "/success-stories/customer-stories" && " active")
                      }
                    >
                      Customer Stories
                    </DropdownItem>
                    {/*<DropdownItem href="/success-stories/employee-stories">*/}
                    {/*    Employee Stories*/}
                    {/*</DropdownItem>*/}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* nav tabs end */}
    </>
  );
};

export default StoryHeader;
