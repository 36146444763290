import { createBrowserRouter } from "react-router-dom"
import App from "../../App"
import ErrorPage from "../pages/404"
import About from "../pages/about/about"
import BlogDetail from "../pages/blogs/blogDetail"
import Blogs from "../pages/blogs/blogs"
import ApplyNow from "../pages/career/applyNow"
import ApplyPosition from "../pages/career/applyPosition"
import Career from "../pages/career/career"
import ContactUs from "../pages/contactUs"
import Investors from "../pages/investors"
import NotRespond from "../pages/notRespond"
import PrivacyPolicy from "../pages/other/privacyPolicy"
import TermsOfUse from "../pages/other/termsOfUse"
import BrandDetail from "../pages/products/brandDetail"
import ProductDetail from "../pages/products/productDetail"
import Products from "../pages/products/products"
import CustomerServices from "../pages/services/customerServices"
import Services from "../pages/services/services"
import CustomerStories from "../pages/successStories/customerStories"
import EmployeeStories from "../pages/successStories/employeeStories"
import FarmerStories from "../pages/successStories/farmerStories"
import StoryAllyBabu from "../pages/successStories/storyAllyBabu"
import StoryDetail from "../pages/successStories/storyDetail"
import StoryExaveryKilumile from "../pages/successStories/storyExaveryKilumile"
import StoryFaustineKilumile from "../pages/successStories/storyFaustineKilumile"
import StoryHappyfaniaGervasKimaro from "../pages/successStories/storyHappyfaniaGervasKimaro"
import StoryJoyceAssenga from "../pages/successStories/storyJoyceAssenga"
import StoryRashidNassor from "../pages/successStories/storyRashidNassor"
import SuccessStories from "../pages/successStories/successStories"
import Testimonials from "../pages/testimonials/testimonials"

const Router: any = createBrowserRouter([
  { path: "*", element: <ErrorPage /> },
  { path: "/index.html", element: <App />, errorElement: <NotRespond/> },
  { path: "/", element: <App />, errorElement: <NotRespond/> },
  { path: "/blog", element: <Blogs />, errorElement: <NotRespond/> },
  { path: "/product", element: <Products />, errorElement: <NotRespond/> },
  { path: "/blog/:blogSlug", element: <BlogDetail />, errorElement: <NotRespond/> },
  { path: "/success-stories", element: <SuccessStories />, errorElement: <NotRespond/> },
  { path: "/success-stories/farmer-stories", element: <FarmerStories />, errorElement: <NotRespond/> },
  { path: "/success-stories/customer-stories", element: <CustomerStories />, errorElement: <NotRespond/> },
  { path: "/success-stories/employee-stories", element: <EmployeeStories />, errorElement: <NotRespond/> },
  {
    path: "/success-stories/:farmerStorySlug",
    element: <StoryDetail />, errorElement: <NotRespond/>,
  },
  {
    path: "/success-stories/story-ExaveryKilumile",
    element: <StoryExaveryKilumile />, errorElement: <NotRespond/>,
  },
  {
    path: "/success-stories/story-FaustineKilumile",
    element: <StoryFaustineKilumile />, errorElement: <NotRespond/>,
  },
  {
    path: "/success-stories/story-HappyfaniaGervasKimaro",
    element: <StoryHappyfaniaGervasKimaro />, errorElement: <NotRespond/>,
  },
  {
    path: "/success-stories/story-JoyceAssenga",
    element: <StoryJoyceAssenga />, errorElement: <NotRespond/>,
  },
  {
    path: "/success-stories/customer-stories/story-AllyBabu",
    element: <StoryAllyBabu />, errorElement: <NotRespond/>,
  },
  {
    path: "/success-stories/customer-stories/story-RashidNassor",
    element: <StoryRashidNassor />, errorElement: <NotRespond/>,
  },
  { path: "/contact-us", element: <ContactUs />, errorElement: <NotRespond/> },
  { path: "/investors-partners", element: <Investors />, errorElement: <NotRespond/> },
  { path: "/testimonials", element: <Testimonials />, errorElement: <NotRespond/> },
  { path: "/brand/:brandSlug", element: <BrandDetail />, errorElement: <NotRespond/> },
  { path: "/product/:productSlug", element: <ProductDetail />, errorElement: <NotRespond/> },
  { path: "/career", element: <Career />, errorElement: <NotRespond/> },
  { path: "/career/:positionSlug", element: <ApplyPosition />, errorElement: <NotRespond/> },
  { path: "/career/apply-now", element: <ApplyNow />, errorElement: <NotRespond/> },
  { path: "/about", element: <About />, errorElement: <NotRespond/> },
  { path: "/farmer-services", element: <Services />, errorElement: <NotRespond/> },
  { path: "/customer-services", element: <CustomerServices />, errorElement: <NotRespond/> },
  { path: "/privacy-policy", element: <PrivacyPolicy />, errorElement: <NotRespond/> },
  { path: "/terms-of-use", element: <TermsOfUse />, errorElement: <NotRespond/> },
  { path: "/not-respond", element: <NotRespond />, errorElement: <NotRespond/> },
]);

export default Router;
