import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Router from "./app/routing/appRoute"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ToastContainer draggable={true} position={"bottom-center"} />
        <RouterProvider router={Router} />
    </React.StrictMode>
);

reportWebVitals();
