import React, { useEffect, useState } from "react";
import {
  NavLink as RNavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import {
  dateFormat,
  goToTop,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { BLOG_STORIES_LIST } from "../../../_eaFruitsCms/https/ApiRoutes";
import { metaImage, toAbsoluteUrl } from "../../../helper/AssetHelpers";
import HeroBanner from "../../../layout/components/banners/heroBanner";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import SectionHeadingStart from "../../../layout/components/headings/sectionHeadingStart";
import NoRecords from "../../../layout/components/noRecords/noRecords";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";
import StoryCard from "../successStories/components/storyCard";
import IsLoading from "../../components/Loader";

const Services = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stories, setStories] = useState<any>([]);
  const [limit, setLimit] = useState<any>(2);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [imageLoad, setImageLoad] = useState<boolean>(false);

  useEffect(() => {
    goToTop(setDisplayLoader);
    fetchFarmerStories().then();
  }, []);

  const fetchFarmerStories = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      BLOG_STORIES_LIST +
        `?limit=${limit}&usertype=Farmer&ordering=-updated_at`,
      navigate,
      false
    );

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
    }

    setStories(response.results);
    setDisplayLoader(false);
  };

  return (
    <>
      <CustomHelmet
        title={`Services to Farmers | EA Foods`}
        metaDescription={`EA foods is building the bridge between local farmers and the market by supporting local farmers and the local economy with exceptional services.`}
        imagePath={metaImage}
      />
      {/* {displayLoader && <CustomLoader />} */}
      <HeroBanner
        heroTitle="Our Services"
        heroDesc="EA Foods services aim at elevating the supply chain to new heights through the provision of high quality products and services to support the growth and success of smallholder farmers and small retailers from different parts of Tanzania."
        path="/media/images/heroservices.jpg"
        heroImageMobile="/media/images/heroServicesM.jpg"
      />

      {/* nav tabs start */}
      <section className="bg-light">
        <Container>
          <Row>
            <Col>
              <div className="d-none d-md-block my-3 my-md-0">
                <Nav className="nav-light" horizontal="center">
                  <NavItem>
                    <NavLink
                      href="/farmer-services"
                      className={
                        `nav-link` +
                        (location.pathname === "/farmer-services" && " active")
                      }
                    >
                      Services to Farmers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RNavLink}
                      to="/customer-services"
                      className={
                        `nav-link` +
                        (location.pathname === "/customer-services" &&
                          " active")
                      }
                    >
                      Services to Customers
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div className="d-block d-md-none my-3">
                <UncontrolledDropdown>
                  <DropdownToggle nav caret>
                    Services to Farmers
                  </DropdownToggle>
                  <DropdownMenu className="w-100">
                    <DropdownItem href="/farmer-services" active>
                      Services to Farmers
                    </DropdownItem>
                    <DropdownItem href="/customer-services">
                      Services to Customer
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* nav tabs end */}

      <section className="section-padding">
        <Container>
          <Row className="leftImageContent align-items-center">
            <Col md={6}>
              <div className="primary-shadow left-shadow rounded">
                <div className="position-relative flex-grow-1">
                  <img
                    src={toAbsoluteUrl("/media/images/service1.jpg")}
                    srcSet={toAbsoluteUrl("/media/images/service1@2x.jpg 2x")}
                    className="rounded img-fluid"
                    alt=""
                    onLoad={() => {
                      setImageLoad(true);
                    }}
                  />
                  {!imageLoad && <span className="skeleton-box"></span>}
                </div>
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <SectionHeadingStart sectionTitle=" Building the bridge between local farmers and the market." />
              <p className="mb-30px">
                At EA Foods, we believe that everyone should have access to
                fresh, locally grown produce. That's why we are using agritech
                to connect farmers directly to the market.
              </p>
              <p className="mb-0">
                By streamlining and optimising the supply chain, we are making
                it easier for consumers to access the highest quality produce,
                while also supporting local farmers and the local economy.
              </p>
            </Col>
          </Row>
          {/* row end */}
        </Container>
      </section>
      {/* right side image section start */}
      <section className="section-padding pt-0">
        <Container>
          <Row className="rightImageContent align-items-center flex-md-row-reverse">
            <Col md={6} className="text-end">
              <div className="primary-shadow right-shadow rounded">
                <div className="position-relative flex-grow-1">
                  <img
                    src={toAbsoluteUrl("/media/images/service2.jpg")}
                    srcSet={toAbsoluteUrl("/media/images/service2@2x.jpg 2x")}
                    className="rounded img-fluid"
                    alt=""
                    onLoad={() => {
                      setImageLoad(true);
                    }}
                  />
                  {!imageLoad && <span className="skeleton-box"></span>}
                </div>
              </div>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <p className="mb-30px">
                In partnership with other international companies such as SNV,
                we have been able to provide training to Irish potato farmers in
                the southern highlands of Tanzania on Climate Smart Agriculture
                (CSA) and Good Agricultural Practices (GAP) through
                demonstration farms. These farmers are divided into groups and
                receive training on seed planning, soil preparation, manuring,
                weeding, harvesting and storage.
              </p>
              <p className="mb-30px">
                We have also reached banana farmers in the northern highlands of
                Tanzania where we source, process quality fresh bananas and
                provide a reliable and stable market to farmers with fair and
                timely payment of all their produce.
              </p>
              <p className="mb-30px">
                We have also introduced them to digital financial systems for
                payments such as banks and mobile money transfers to ensure
                money safety and help them with better financial management.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* end */}

      <section className="section-padding pt-0">
        <Container>
          <Row>
            <Col md={4} lg={4} className="mt-30px">
              <div className={`bg-white text-center text-dark h-100`}>
                <img
                  src={toAbsoluteUrl(
                    "/media/illustrations/icon_farmer_service1.svg"
                  )}
                  className="img-fluid mb-10"
                  alt=""
                />
                <h3 className="font-16 fw-700 mb-10">
                  Increasing Coverage and Capacity
                </h3>
                <p className="mb-0">
                  We have also reached another set of 5,000 banana farmers in
                  the northern highlands of Tanzania where we source, process
                  quality fresh bananas whose demand has grown from 5,400 tonnes
                  in 2021 to 7,257 tonnes in 2022.
                </p>
              </div>
            </Col>
            <Col md={4} lg={4} className="mt-30px">
              <div className={`bg-white text-center text-dark h-100`}>
                <img
                  src={toAbsoluteUrl(
                    "/media/illustrations/icon_farmer_service2.svg"
                  )}
                  className="img-fluid mb-10"
                  alt=""
                />
                <h3 className="font-16 fw-700 mb-10">
                  Supporting Farmers in Each Step
                </h3>
                <p className="mb-0">
                  These farmers are divided into groups and receive training on
                  seed planning, soil preparation, manuring, weeding, harvesting
                  and storage. We also provide them with reliable and assured
                  markets with fair prices.
                </p>
              </div>
            </Col>
            <Col md={4} lg={4} className="mt-30px">
              <div className={`bg-white text-center text-dark h-100`}>
                <img
                  src={toAbsoluteUrl(
                    "/media/illustrations/icon_farmer_service3.svg"
                  )}
                  className="img-fluid mb-10"
                  alt=""
                />
                <h3 className="font-16 fw-700 mb-10">Financial Support</h3>
                <p className="mb-0">
                  We have also introduced them to digital financial systems for
                  payments such as banks and mobile money transfers to ensure
                  money safety and help them with better financial management.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*Farmers Success Story start */}
      <section className="section-padding pt-0">
        <SectionHeading
          className="pb-20px"
          sectionTitle="Farmers Success Story"
          sectionPara=""
        />
        <Container>
          <Row>
            {displayLoader ? (
              <>
                <Row>
                  <Col xs="6" className="text-center mt-30px">
                    <div
                      className="position-relative"
                      style={{ height: "400px" }}
                    >
                      <span className="skeleton-box"></span>
                    </div>
                  </Col>
                  <Col xs="6" className="text-center mt-30px">
                    <div
                      className="position-relative"
                      style={{ height: "400px" }}
                    >
                      <span className="skeleton-box"></span>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {stories.length === 0 ? (
                  <NoRecords />
                ) : (
                  stories.map((item: any) => {
                    return (
                      <>
                        <Col md="12" lg="6" className="mt-30px">
                          <a
                            className="text-dark cursor-pointer"
                            onClick={() => {
                              navigate(`/success-stories/${item.slug}`);
                            }}
                          >
                            <StoryCard
                              title={item.story_title}
                              name={item.firstname}
                              lastname={item.lastname}
                              subtitle={item.subtitle}
                              description={item.story_short_description}
                              category="Farmer"
                              address={item.location_text}
                              date={dateFormat(item.publish_date).displayDate}
                              path={item.profile_picture}
                            />
                          </a>
                        </Col>
                      </>
                    );
                  })
                )}
                {loadMore && (
                  <Col xs="12" className="text-center mt-30px">
                    <Button
                      color="primary"
                      outline
                      className="btn-rounded"
                      onClick={() =>
                        navigate("/success-stories/farmer-stories")
                      }
                    >
                      Show More
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </section>
      {/* Farmers Success Story end */}
    </>
  );
};

export default MasterLayout(Services);
