import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  goToTop,
  MOBILE_MAX_LENGTH,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest, postRequest } from "../../../_eaFruitsCms/https/apiCall";
import {
  APPLICATION,
  SINGLE_CURRENT_OPENING,
} from "../../../_eaFruitsCms/https/ApiRoutes";
import ErrorHandler from "../../../_eaFruitsCms/validation/ErrorHandler";
import { validateEmail } from "../../../_eaFruitsCms/validation/validation";
import { KTSVG } from "../../../helper/components/KTSVG";
import SectionHeading from "../../../layout/components/headings/sectionHeading";
import MasterLayout from "../../../layout/masterlayout";
import CustomLoader from "../../components/CustomLoader";
import CustomReCaptcha from "../../components/CustomReCaptcha";
import { successToast } from "../../../_eaFruitsCms/helpers/CommonHelpers";

const ApplyPosition = () => {
  const currentURL = window.location.href;

  let { positionSlug } = useParams();
  const navigate = useNavigate();

  const [captchaValid, setCaptchaValid] = useState<boolean>(false);
  const [captchaReset, setCaptchaReset] = useState<boolean>(false);

  const initialRow: any = {
    currentopenings_id: 0,
    firstname: "",
    lastname: "",
    email: "",
    phone_no: "",
    address: "",
    resume: "",
  };

  const initialValidation: any = {
    firstname: "",
    lastname: "",
    email: "",
    phone_no: "",
    address: "",
    resume: "",
  };

  const [currentOpenings, setCurrentOpenings] = useState<any>([]);
  const [detailsValidation, setDetailsValidation] =
    useState<any>(initialValidation);
  const [details, setDetails] = useState<any>(initialRow);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [sizerMessage, setSizeMessage] = useState("");

  const toggleCategory = () => setIsOpen(!isOpen);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [positionId, setPositionId] = useState(0);

  useEffect(() => {
    goToTop();
  }, []);

  useEffect(() => {
    fetchCurrentOpening().then();
  }, []);

  const fetchCurrentOpening = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      SINGLE_CURRENT_OPENING + `${positionSlug}/`,
      navigate,
      false
    );

    setPositionId(response.id);
    setCurrentOpenings(response);
    setDisplayLoader(false);
  };

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name;
    let inputValue = e.target ? e.target.value : e.value;

    setDetailsValidation({ ...detailsValidation, [inputName]: "" });

    switch (inputName) {
      case "firstname":
        setDetails({
          ...details,
          ["firstname"]: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "lastname":
        setDetails({
          ...details,
          ["lastname"]: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "email":
        setDetails({ ...details, ["email"]: inputValue });
        setErrorMessage("");
        break;
      case "phone_no":
        const re = /^[0-9\b]+$/;
        if (inputValue === "" || re.test(inputValue)) {
          setDetails({ ...details, ["phone_no"]: e.target.value });
        }
        break;
      case "address":
        setDetails({ ...details, ["address"]: inputValue });
        break;
      case "resume":
        if (e.target.files[0].size > 5000000) {
          setSizeMessage("Maximum allowed file size is 5mb");
          setDetails({ ...details, ["resume"]: e.target.files[0] });
          // > 5mb file will do not allow
        } else {
          setSizeMessage("");
          setDetails({ ...details, ["resume"]: e.target.files[0] });
        }
        break;
    }
  };

  const validateForm = () => {
    let validations = { ...detailsValidation };
    let isValid = true;

    if (details.firstname == "") {
      validations.firstname = "is-invalid";
      isValid = false;
    }

    if (details.lastname.trim() == "") {
      validations.lastname = "is-invalid";
      isValid = false;
    }

    if (details.email === "") {
      validations.email = "is-invalid";
      isValid = false;
    } else {
      if (!validateEmail(details.email)) {
        setDetailsValidation({
          ...detailsValidation,
          ["email"]: "is-invalid",
        });
        isValid = false;
      }
    }

    if (details.phone_no.trim() == "") {
      validations.phone_no = "is-invalid";
      isValid = false;
    }

    if (details.address.trim() == "") {
      validations.address = "is-invalid";
      isValid = false;
    }

    if (
      details.resume == "" ||
      sizerMessage == "Maximum allowed file size is 1mb"
    ) {
      validations.resume = "is-invalid";
      isValid = false;
    }

    if (!isValid) {
      setCaptchaReset(!captchaReset);
    }

    setDetailsValidation(validations);
    return isValid;
  };

  const submitForm = () => {
    const isValid = validateForm();
    if (isValid) {
      let applicationData = new FormData();
      const detailsInfo = { ...details };
      detailsInfo.currentopenings_id = positionId;
      //      applicationData.append('currentopenings_id', positionId + "")
      Object.entries(detailsInfo).map((object: any) => {
        return applicationData.append(object[0], object[1]);
      });

      setButtonText("Please Wait...");

      postRequest(APPLICATION, applicationData, navigate, false).then(
        (res: any) => {
          if (res.status === 201) {
            {
              // toggleModal();
              setDetails(initialRow);
              successToast(
                "Thank you, your job application has been received successfully!"
              );
              setErrorMessage("");
            }
          } else {
            setErrorMessage("Application with this email already exists.");
          }
          setButtonText("Submit");
          setCaptchaReset(!captchaReset);
        }
      );
    }
  };

  return (
    <>
      {displayLoader && <CustomLoader />}
      <section className="section-padding bg-light post-banner">
        <Container>
          <Row>
            <Col>
              <div>
                <h2 className="font-28 mb-10">{currentOpenings.title}</h2>
                <div className="d-flex">
                  <span className="me-3">
                    <KTSVG
                      path="/media/icons/ic_location.svg"
                      className="ic me-1"
                    />
                    {currentOpenings.location}
                  </span>
                  <span>
                    <KTSVG
                      path="/media/icons/ic_time.svg"
                      className="ic me-1"
                    />
                    {currentOpenings.jobtype == "PartTime"
                      ? "Part Time"
                      : "Full Time"}
                  </span>
                </div>
              </div>
            </Col>
            {/* <Col md={ "auto" }>
							<Button
								color="primary"
								outline
								className="flex-grow-1 mt-3 mt-md-0 d-flex align-items-center justify-content-center btn-rounded"
							>
								<KTSVG path="/media/icons/ic_share.svg" className="ic me-2" />
								Share
							</Button>
						</Col> */}
            <Col className="text-md-end social" sm="6">
              <div className="social d-flex align-items-center justify-content-sm-end mt-4 mt-md-0">
                <span className="fw-600">Share this on</span>
                <ul className="text-white list-unstyled mb-0 d-inline-flex ms-3">
                  <li className="me-3">
                    <FacebookShareButton
                      quote={`${
                        currentOpenings && currentOpenings.title
                      } | EA Foods`}
                      url={currentURL}
                      title={`${
                        currentOpenings && currentOpenings.title
                      } | EA Foods`}
                    >
                      <KTSVG
                        path="/media/icons/ic_facebook.svg"
                        className="ic text-primary"
                      />
                    </FacebookShareButton>
                  </li>
                  <li className="me-3">
                    <TwitterShareButton
                      title={`${
                        currentOpenings && currentOpenings.title
                      } | EA Foods`}
                      url={currentURL}
                    >
                      <KTSVG
                        path="/media/icons/ic_twitter.svg"
                        className="ic text-primary"
                      />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      title={`${
                        currentOpenings && currentOpenings.title
                      } | EA Foods`}
                      summary={`${
                        currentOpenings && currentOpenings.title
                      } | EA Foods`}
                      url={currentURL}
                    >
                      <KTSVG
                        path="/media/icons/ic_linkedin.svg"
                        className="ic text-primary"
                      />
                    </LinkedinShareButton>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Apply for this position start */}
      <section className="section-padding">
        <SectionHeading
          className="pb-20px"
          sectionTitle="Apply for this position"
        />
        <Container>
          <Row>
            <Col md={7} lg={7} className="mt-30px">
              <div
                dangerouslySetInnerHTML={{
                  __html: currentOpenings.jobdiscription,
                }}
              />
            </Col>
            <Col md={5} lg={5}>
              <div className="bg-light apply-form rounded">
                <Form>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="fname">
                          First Name
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="fname"
                          name="firstname"
                          placeholder="Type here..."
                          type="text"
                          className={clsx(
                            "form-control",
                            detailsValidation.firstname
                          )}
                          value={details.firstname}
                          onChange={(e) => handleChange(e, "firstname")}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="lname">
                          Last Name
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="lname"
                          name="lastname"
                          className={clsx(
                            "form-control",
                            detailsValidation.lastname
                          )}
                          placeholder="Type here..."
                          type="text"
                          value={details.lastname}
                          onChange={(e) => handleChange(e, "lastname")}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="email">
                          Email
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          className={clsx(
                            "form-control",
                            detailsValidation.email
                          )}
                          onBlur={(e) => {
                            if (!validateEmail(e.target.value)) {
                              setDetailsValidation({
                                ...detailsValidation,
                                ["email"]: "is-invalid",
                              });
                            }
                          }}
                          placeholder="Type here..."
                          type="text"
                          value={details.email}
                          onChange={(e) => handleChange(e, "email")}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label for="phone">
                          Phone Number<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="phone"
                          name="phone_no"
                          maxLength={MOBILE_MAX_LENGTH}
                          placeholder="Type here..."
                          type="text"
                          value={details.phone_no}
                          className={clsx(
                            "form-control",
                            detailsValidation.phone_no
                          )}
                          onChange={(e) => handleChange(e, "phone_no")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label for="address">
                          Address<span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="address"
                          name="address"
                          placeholder="Street Address"
                          className={clsx(
                            "form-control",
                            detailsValidation.address
                          )}
                          type="text"
                          value={details.address}
                          onChange={(e) => handleChange(e, "address")}
                        />
                      </FormGroup>
                    </Col>
                    <FormGroup>
                      <Label for="resume">
                        Resume<span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative cursor-pointer">
                        <Label
                          for="resume"
                          className={clsx(
                            "form-control file-input text-truncate pe-5",
                            detailsValidation.resume
                          )}
                        >
                          <span
                            className={clsx(
                              "text-muted",
                              detailsValidation.resume
                            )}
                          >
                            {details.resume
                              ? details.resume.name
                              : "Attach Document"}
                          </span>
                        </Label>
                        <Input
                          id="resume"
                          name="resume"
                          placeholder="Attach Document"
                          type="file"
                          className=" form-control invisible position-absolute is-invalid"
                          accept="application/pdf, image/*, application/msword"
                          onChange={(e) => handleChange(e, "resume")}
                        />
                      </div>
                    </FormGroup>
                    <ErrorHandler errorMessage={sizerMessage} />
                    <ErrorHandler errorMessage={errorMessage} />
                  </Row>
                  <div className=" mt-3">
                    <CustomReCaptcha
                      setCaptchaValid={setCaptchaValid}
                      captchaReset={captchaReset}
                    />
                  </div>
                  <Button
                    className="btn-rounded mt-3"
                    color="primary"
                    outline
                    disabled={!captchaValid || buttonText !== "Submit"}
                    onClick={submitForm}
                  >
                    {buttonText}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Apply for this position end */}

      {/* Successful message alert */}
      {/* <Modal */}
      {/* // isOpen={modal}
        // toggle={toggleModal}
        // centered
        // size={"lg"} */}
      {/* > */}
      {/* <ModalHeader
          toggle={toggleModal}
          className="bg-primary text-white"
        ></ModalHeader>
        <ModalBody className="text-center px-lg-5 py-4 px-4 py-lg-5">
          <KTSVG
            path="/media/icons/ic_check.svg"
            className="ic me-2 mb-20px text-dark"
          />
          <h3 className="fw-700 font-20">Message Sent Successful</h3>
          <p>
            Your message has been sent successfuly, I hope to respond within 24
            hours. You can also contact us through links can be found below!
          </p>
        </ModalBody> */}
      {/* <ModalFooter>
				 <Button color="primary" onClick={toggleModal}>
				 Do Something
				 </Button>{' '}
				 <Button color="secondary" onClick={toggleModal}>
				 Cancel
				 </Button>
				 </ModalFooter> */}
      {/* </Modal> */}
    </>
  );
};

export default MasterLayout(ApplyPosition);
