import React from "react"

const SvgSprite = (props: any) => {
    return (
        <>
            <div className="d-none">
                <svg width="0" height="0" className="hidden">
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_camera_plus">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 10.75C10.7574 10.75 9.75 11.7574 9.75 13C9.75 14.2426 10.7574 15.25 12 15.25C13.2426 15.25 14.25 14.2426 14.25 13C14.25 11.7574 13.2426 10.75 12 10.75ZM8.25 13C8.25 10.9289 9.92893 9.25 12 9.25C14.0711 9.25 15.75 10.9289 15.75 13C15.75 15.0711 14.0711 16.75 12 16.75C9.92893 16.75 8.25 15.0711 8.25 13Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 4.75C9.9337 4.75 9.87011 4.77634 9.82322 4.82322C9.77634 4.87011 9.75 4.9337 9.75 5C9.75 5.72935 9.46027 6.42882 8.94454 6.94454C8.42882 7.46027 7.72935 7.75 7 7.75H5C4.66848 7.75 4.35054 7.8817 4.11612 8.11612C3.8817 8.35054 3.75 8.66848 3.75 9V18C3.75 18.3315 3.8817 18.6495 4.11612 18.8839C4.35054 19.1183 4.66848 19.25 5 19.25H19C19.3315 19.25 19.6495 19.1183 19.8839 18.8839C20.1183 18.6495 20.25 18.3315 20.25 18V11C20.25 10.5858 20.5858 10.25 21 10.25C21.4142 10.25 21.75 10.5858 21.75 11V18C21.75 18.7293 21.4603 19.4288 20.9445 19.9445C20.4288 20.4603 19.7293 20.75 19 20.75H5C4.27065 20.75 3.57118 20.4603 3.05546 19.9445C2.53973 19.4288 2.25 18.7293 2.25 18V9C2.25 8.27065 2.53973 7.57118 3.05546 7.05546C3.57118 6.53973 4.27065 6.25 5 6.25H7C7.33152 6.25 7.64946 6.1183 7.88388 5.88388C8.1183 5.64946 8.25 5.33152 8.25 5C8.25 4.53587 8.43437 4.09075 8.76256 3.76256C9.09075 3.43438 9.53587 3.25 10 3.25H12C12.4142 3.25 12.75 3.58579 12.75 4C12.75 4.41421 12.4142 4.75 12 4.75H10Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.25 6C14.25 5.58579 14.5858 5.25 15 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H15C14.5858 6.75 14.25 6.41421 14.25 6Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18 2.25C18.4142 2.25 18.75 2.58579 18.75 3V9C18.75 9.41421 18.4142 9.75 18 9.75C17.5858 9.75 17.25 9.41421 17.25 9V3C17.25 2.58579 17.5858 2.25 18 2.25Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_caret_down">
                        <path d="M6 9L12 15L18 9H6Z" fill="#888888"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_check">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.5303 9.46967C15.8232 9.76256 15.8232 10.2374 15.5303 10.5303L11.5303 14.5303C11.2374 14.8232 10.7626 14.8232 10.4697 14.5303L8.46967 12.5303C8.17678 12.2374 8.17678 11.7626 8.46967 11.4697C8.76256 11.1768 9.23744 11.1768 9.53033 11.4697L11 12.9393L14.4697 9.46967C14.7626 9.17678 15.2374 9.17678 15.5303 9.46967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_chevron_down">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_chevron_left">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2803 5.21967C15.5732 5.51256 15.5732 5.98744 15.2803 6.28033L9.81066 11.75L15.2803 17.2197C15.5732 17.5126 15.5732 17.9874 15.2803 18.2803C14.9874 18.5732 14.5126 18.5732 14.2197 18.2803L8.21967 12.2803C7.92678 11.9874 7.92678 11.5126 8.21967 11.2197L14.2197 5.21967C14.5126 4.92678 14.9874 4.92678 15.2803 5.21967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_chevron_right">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.46967 5.46967C8.76256 5.17678 9.23744 5.17678 9.53033 5.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L9.53033 18.5303C9.23744 18.8232 8.76256 18.8232 8.46967 18.5303C8.17678 18.2374 8.17678 17.7626 8.46967 17.4697L13.9393 12L8.46967 6.53033C8.17678 6.23744 8.17678 5.76256 8.46967 5.46967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_circle">
                        <g clipPath="url(#clip0_1862_7438)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="currentColor"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_1862_7438">
                                <rect width="24" height="24" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_circle_radio">
                        <g clipPath="url(#clip0_1862_7441)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z" fill="currentColor"></path>
                        </g>
                        <defs>
                            <clipPath id="clip0_1862_7441">
                                <rect width="24" height="24" fill="white"></rect>
                            </clipPath>
                        </defs>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_date">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 5.75C5.30964 5.75 4.75 6.30964 4.75 7V19C4.75 19.6904 5.30964 20.25 6 20.25H18C18.6904 20.25 19.25 19.6904 19.25 19V7C19.25 6.30964 18.6904 5.75 18 5.75H6ZM3.25 7C3.25 5.48122 4.48122 4.25 6 4.25H18C19.5188 4.25 20.75 5.48122 20.75 7V19C20.75 20.5188 19.5188 21.75 18 21.75H6C4.48122 21.75 3.25 20.5188 3.25 19V7Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M16 2.25C16.4142 2.25 16.75 2.58579 16.75 3V7C16.75 7.41421 16.4142 7.75 16 7.75C15.5858 7.75 15.25 7.41421 15.25 7V3C15.25 2.58579 15.5858 2.25 16 2.25Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 2.25C8.41421 2.25 8.75 2.58579 8.75 3V7C8.75 7.41421 8.41421 7.75 8 7.75C7.58579 7.75 7.25 7.41421 7.25 7V3C7.25 2.58579 7.58579 2.25 8 2.25Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.25 11C3.25 10.5858 3.58579 10.25 4 10.25H20C20.4142 10.25 20.75 10.5858 20.75 11C20.75 11.4142 20.4142 11.75 20 11.75H4C3.58579 11.75 3.25 11.4142 3.25 11Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H10C10.4142 14.25 10.75 14.5858 10.75 15V17C10.75 17.4142 10.4142 17.75 10 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17V15ZM8.75 15.75V16.25H9.25V15.75H8.75Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_document">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 3.46967C12.406 2.53336 13.6759 2.00735 15 2.00735C16.3241 2.00735 17.594 2.53336 18.5303 3.46967C19.4666 4.40597 19.9926 5.67586 19.9926 7C19.9927 8.32413 19.4666 9.59402 18.5303 10.5303L12.0303 17.0303C11.4919 17.5688 10.7615 17.8713 10 17.8713C9.23849 17.8713 8.50816 17.5688 7.96968 17.0303C7.4312 16.4918 7.12869 15.7615 7.12869 15C7.12869 14.2385 7.4312 13.5081 7.96968 12.9697L14.4697 6.46967C14.7626 6.17677 15.2374 6.17677 15.5303 6.46967C15.8232 6.76256 15.8232 7.23743 15.5303 7.53033L9.03034 14.0303C8.77317 14.2875 8.62869 14.6363 8.62869 15C8.62869 15.3637 8.77317 15.7125 9.03034 15.9697C9.28751 16.2268 9.63631 16.3713 10 16.3713C10.3637 16.3713 10.7125 16.2268 10.9697 15.9697L17.4697 9.46967C18.1247 8.81467 18.4927 7.9263 18.4927 7C18.4927 6.07369 18.1247 5.18532 17.4697 4.53033C16.8147 3.87533 15.9263 3.50735 15 3.50735C14.0737 3.50735 13.1853 3.87533 12.5303 4.53033L6.03034 11.0303C4.97752 12.0831 4.38605 13.5111 4.38605 15C4.38605 16.4889 4.97752 17.9168 6.03034 18.9697C7.08316 20.0225 8.51109 20.614 10 20.614C11.4889 20.614 12.9169 20.0225 13.9697 18.9697L20.4697 12.4697C20.7626 12.1768 21.2374 12.1768 21.5303 12.4697C21.8232 12.7626 21.8232 13.2374 21.5303 13.5303L15.0303 20.0303C13.6962 21.3645 11.8867 22.114 10 22.114C8.11327 22.114 6.3038 21.3645 4.96968 20.0303C3.63555 18.6962 2.88605 16.8867 2.88605 15C2.88605 13.1133 3.63555 11.3038 4.96968 9.96967L11.4697 3.46967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_facebook">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.568 6.31802C11.4119 5.47411 12.5565 5 13.75 5H16C16.4142 5 16.75 5.33579 16.75 5.75V8.75C16.75 9.16421 16.4142 9.5 16 9.5H13.75V10.25H16C16.231 10.25 16.449 10.3564 16.5912 10.5384C16.7333 10.7205 16.7836 10.9578 16.7276 11.1819L15.9776 14.1819C15.8941 14.5158 15.5942 14.75 15.25 14.75H13.75V19.25C13.75 19.6642 13.4142 20 13 20H10C9.58579 20 9.25 19.6642 9.25 19.25V14.75H7.75C7.33579 14.75 7 14.4142 7 14V11C7 10.5858 7.33579 10.25 7.75 10.25H9.25V9.5C9.25 8.30653 9.72411 7.16193 10.568 6.31802ZM13.75 6.5C12.9544 6.5 12.1913 6.81607 11.6287 7.37868C11.0661 7.94129 10.75 8.70435 10.75 9.5V11C10.75 11.4142 10.4142 11.75 10 11.75H8.5V13.25H10C10.4142 13.25 10.75 13.5858 10.75 14V18.5H12.25V14C12.25 13.5858 12.5858 13.25 13 13.25H14.6644L15.0394 11.75H13C12.5858 11.75 12.25 11.4142 12.25 11V9.5C12.25 9.10217 12.408 8.72064 12.6893 8.43934C12.9706 8.15804 13.3522 8 13.75 8H15.25V6.5H13.75Z" fill="white"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_fill_star">
                        <path d="M12.2794 17.8972C12.1045 17.8051 11.8954 17.805 11.7205 17.897L6.90413 20.4292C6.46394 20.6607 5.94947 20.2869 6.03355 19.7967L6.95361 14.4332C6.98702 14.2384 6.92237 14.0396 6.78076 13.9018L2.87933 10.1041C2.52276 9.75707 2.71932 9.15178 3.21178 9.08041L8.59467 8.30028C8.79018 8.27194 8.95922 8.14918 9.04665 7.97202L11.4549 3.09222C11.675 2.64627 12.3109 2.64627 12.531 3.09222L14.9393 7.97202C15.0268 8.14918 15.1958 8.27194 15.3913 8.30028L20.7742 9.08041C21.2667 9.15178 21.4632 9.75707 21.1067 10.1041L17.2052 13.9018C17.0636 14.0396 16.999 14.2384 17.0324 14.4332L17.9522 19.7953C18.0363 20.2857 17.5213 20.6595 17.0811 20.4275L12.2794 17.8972Z" fill="#F9C527"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_insta">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.75 6.5C7.50736 6.5 6.5 7.50736 6.5 8.75V14.75C6.5 15.9926 7.50736 17 8.75 17H14.75C15.9926 17 17 15.9926 17 14.75V8.75C17 7.50736 15.9926 6.5 14.75 6.5H8.75ZM5 8.75C5 6.67893 6.67893 5 8.75 5H14.75C16.8211 5 18.5 6.67893 18.5 8.75V14.75C18.5 16.8211 16.8211 18.5 14.75 18.5H8.75C6.67893 18.5 5 16.8211 5 14.75V8.75Z" fill="white"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.75 10.25C10.9216 10.25 10.25 10.9216 10.25 11.75C10.25 12.5784 10.9216 13.25 11.75 13.25C12.5784 13.25 13.25 12.5784 13.25 11.75C13.25 10.9216 12.5784 10.25 11.75 10.25ZM8.75 11.75C8.75 10.0931 10.0931 8.75 11.75 8.75C13.4069 8.75 14.75 10.0931 14.75 11.75C14.75 13.4069 13.4069 14.75 11.75 14.75C10.0931 14.75 8.75 13.4069 8.75 11.75Z" fill="white"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.125 7.625C15.5392 7.625 15.875 7.96079 15.875 8.375C15.875 8.78921 15.5392 9.126 15.125 9.126C14.7108 9.126 14.375 8.79021 14.375 8.376C14.375 7.96179 14.7108 7.625 15.125 7.625Z" fill="white"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_left">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.46967 11.4697C4.76256 11.1768 5.23744 11.1768 5.53033 11.4697L11.5303 17.4697C11.8232 17.7626 11.8232 18.2374 11.5303 18.5303C11.2374 18.8232 10.7626 18.8232 10.4697 18.5303L4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.5303 5.46967C11.8232 5.76256 11.8232 6.23744 11.5303 6.53033L5.53033 12.5303C5.23744 12.8232 4.76256 12.8232 4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L10.4697 5.46967C10.7626 5.17678 11.2374 5.17678 11.5303 5.46967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_linkedin">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.25 6.5C6.83579 6.5 6.5 6.83579 6.5 7.25V16.25C6.5 16.6642 6.83579 17 7.25 17H16.25C16.6642 17 17 16.6642 17 16.25V7.25C17 6.83579 16.6642 6.5 16.25 6.5H7.25ZM5 7.25C5 6.00736 6.00736 5 7.25 5H16.25C17.4926 5 18.5 6.00736 18.5 7.25V16.25C18.5 17.4926 17.4926 18.5 16.25 18.5H7.25C6.00736 18.5 5 17.4926 5 16.25V7.25Z" fill="white"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.75 10.25C9.16421 10.25 9.5 10.5858 9.5 11V14.75C9.5 15.1642 9.16421 15.5 8.75 15.5C8.33579 15.5 8 15.1642 8 14.75V11C8 10.5858 8.33579 10.25 8.75 10.25Z" fill="white"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.75 8C9.16421 8 9.5 8.33579 9.5 8.75V8.7575C9.5 9.17171 9.16421 9.5075 8.75 9.5075C8.33579 9.5075 8 9.17171 8 8.7575V8.75C8 8.33579 8.33579 8 8.75 8Z" fill="white"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.75 10.25C12.1642 10.25 12.5 10.5858 12.5 11V14.75C12.5 15.1642 12.1642 15.5 11.75 15.5C11.3358 15.5 11 15.1642 11 14.75V11C11 10.5858 11.3358 10.25 11.75 10.25Z" fill="white"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.659 10.909C12.081 10.4871 12.6533 10.25 13.25 10.25C13.8467 10.25 14.419 10.4871 14.841 10.909C15.2629 11.331 15.5 11.9033 15.5 12.5V14.75C15.5 15.1642 15.1642 15.5 14.75 15.5C14.3358 15.5 14 15.1642 14 14.75V12.5C14 12.3011 13.921 12.1103 13.7803 11.9697C13.6397 11.829 13.4489 11.75 13.25 11.75C13.0511 11.75 12.8603 11.829 12.7197 11.9697C12.579 12.1103 12.5 12.3011 12.5 12.5C12.5 12.9142 12.1642 13.25 11.75 13.25C11.3358 13.25 11 12.9142 11 12.5C11 11.9033 11.2371 11.331 11.659 10.909Z" fill="white"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_location">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 8.75001C10.7574 8.75001 9.75 9.75737 9.75 11C9.75 12.2427 10.7574 13.25 12 13.25C13.2426 13.25 14.25 12.2427 14.25 11C14.25 9.75737 13.2426 8.75001 12 8.75001ZM8.25 11C8.25 8.92895 9.92894 7.25001 12 7.25001C14.0711 7.25001 15.75 8.92895 15.75 11C15.75 13.0711 14.0711 14.75 12 14.75C9.92894 14.75 8.25 13.0711 8.25 11Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75031C10.5661 3.75031 9.16441 4.1755 7.97216 4.97212C6.77991 5.76875 5.85066 6.90102 5.30191 8.22576C4.75317 9.55051 4.60957 11.0082 4.88928 12.4146C5.16899 13.8209 5.85942 15.1127 6.87327 16.1266L11.1171 20.3694C11.3515 20.6036 11.6692 20.7351 12.0005 20.7351C12.3318 20.7351 12.6496 20.6036 12.8839 20.3694L17.1267 16.1267C18.1406 15.1128 18.831 13.8209 19.1107 12.4146C19.3904 11.0082 19.2468 9.55051 18.6981 8.22576C18.1493 6.90102 17.2201 5.76875 16.0278 4.97212C14.8356 4.1755 13.4339 3.75031 12 3.75031ZM18.1873 17.1873C19.411 15.9636 20.2443 14.4045 20.5819 12.7072C20.9195 11.0099 20.7462 9.25055 20.0839 7.65172C19.4216 6.05289 18.3001 4.68636 16.8612 3.72491C15.4223 2.76347 13.7306 2.25031 12 2.25031C10.2694 2.25031 8.57773 2.76347 7.13881 3.72491C5.69989 4.68636 4.57838 6.05289 3.9161 7.65172C3.25382 9.25055 3.08052 11.0099 3.4181 12.7072C3.75568 14.4045 4.589 15.9636 5.81266 17.1873L10.0567 21.4304C10.5724 21.9455 11.2716 22.2351 12.0005 22.2351C12.7294 22.2351 13.4284 21.9457 13.9441 21.4306L18.1873 17.1873Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_mail">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.75006 5.5C4.05971 5.5 3.50006 6.05964 3.50006 6.75V16.75C3.50006 17.4404 4.05971 18 4.75006 18H18.7501C19.4404 18 20.0001 17.4404 20.0001 16.75V6.75C20.0001 6.05964 19.4404 5.5 18.7501 5.5H4.75006ZM2.00006 6.75C2.00006 5.23122 3.23128 4 4.75006 4H18.7501C20.2688 4 21.5001 5.23122 21.5001 6.75V16.75C21.5001 18.2688 20.2688 19.5 18.7501 19.5H4.75006C3.23128 19.5 2.00006 18.2688 2.00006 16.75V6.75Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.12603 6.33397C2.35579 5.98933 2.82144 5.8962 3.16609 6.12596L11.7501 11.8486L20.334 6.12596C20.6787 5.8962 21.1443 5.98933 21.3741 6.33397C21.6039 6.67862 21.5107 7.14427 21.1661 7.37404L12.1661 13.374C11.9142 13.542 11.586 13.542 11.334 13.374L2.33404 7.37404C1.98939 7.14427 1.89626 6.67862 2.12603 6.33397Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_right">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4 11.75C4 11.3358 4.33579 11 4.75 11H18.75C19.1642 11 19.5 11.3358 19.5 11.75C19.5 12.1642 19.1642 12.5 18.75 12.5H4.75C4.33579 12.5 4 12.1642 4 11.75Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.2803 11.2197C19.5732 11.5126 19.5732 11.9874 19.2803 12.2803L13.2803 18.2803C12.9874 18.5732 12.5126 18.5732 12.2197 18.2803C11.9268 17.9874 11.9268 17.5126 12.2197 17.2197L18.2197 11.2197C18.5126 10.9268 18.9874 10.9268 19.2803 11.2197Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2197 5.21967C12.5126 4.92678 12.9874 4.92678 13.2803 5.21967L19.2803 11.2197C19.5732 11.5126 19.5732 11.9874 19.2803 12.2803C18.9874 12.5732 18.5126 12.5732 18.2197 12.2803L12.2197 6.28033C11.9268 5.98744 11.9268 5.51256 12.2197 5.21967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_search">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.75 3.5C6.29822 3.5 3.5 6.29822 3.5 9.75C3.5 13.2018 6.29822 16 9.75 16C13.2018 16 16 13.2018 16 9.75C16 6.29822 13.2018 3.5 9.75 3.5ZM2 9.75C2 5.46979 5.46979 2 9.75 2C14.0302 2 17.5 5.46979 17.5 9.75C17.5 14.0302 14.0302 17.5 9.75 17.5C5.46979 17.5 2 14.0302 2 9.75Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.2197 14.2197C14.5126 13.9268 14.9874 13.9268 15.2803 14.2197L21.2803 20.2197C21.5732 20.5126 21.5732 20.9874 21.2803 21.2803C20.9874 21.5732 20.5126 21.5732 20.2197 21.2803L14.2197 15.2803C13.9268 14.9874 13.9268 14.5126 14.2197 14.2197Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_share">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 9.75C4.75736 9.75 3.75 10.7574 3.75 12C3.75 13.2426 4.75736 14.25 6 14.25C7.24264 14.25 8.25 13.2426 8.25 12C8.25 10.7574 7.24264 9.75 6 9.75ZM2.25 12C2.25 9.92893 3.92893 8.25 6 8.25C8.07107 8.25 9.75 9.92893 9.75 12C9.75 14.0711 8.07107 15.75 6 15.75C3.92893 15.75 2.25 14.0711 2.25 12Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18 3.75C16.7574 3.75 15.75 4.75736 15.75 6C15.75 7.24264 16.7574 8.25 18 8.25C19.2426 8.25 20.25 7.24264 20.25 6C20.25 4.75736 19.2426 3.75 18 3.75ZM14.25 6C14.25 3.92893 15.9289 2.25 18 2.25C20.0711 2.25 21.75 3.92893 21.75 6C21.75 8.07107 20.0711 9.75 18 9.75C15.9289 9.75 14.25 8.07107 14.25 6Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18 15.75C16.7574 15.75 15.75 16.7574 15.75 18C15.75 19.2426 16.7574 20.25 18 20.25C19.2426 20.25 20.25 19.2426 20.25 18C20.25 16.7574 19.2426 15.75 18 15.75ZM14.25 18C14.25 15.9289 15.9289 14.25 18 14.25C20.0711 14.25 21.75 15.9289 21.75 18C21.75 20.0711 20.0711 21.75 18 21.75C15.9289 21.75 14.25 20.0711 14.25 18Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.9667 6.95653C16.1564 7.32476 16.0117 7.77704 15.6435 7.96673L9.04347 11.3667C8.67524 11.5564 8.22296 11.4117 8.03327 11.0435C7.84358 10.6752 7.98831 10.223 8.35653 10.0333L14.9565 6.63327C15.3248 6.44358 15.777 6.58831 15.9667 6.95653Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.03327 12.9565C8.22296 12.5883 8.67524 12.4436 9.04347 12.6333L15.6435 16.0333C16.0117 16.223 16.1564 16.6752 15.9667 17.0435C15.777 17.4117 15.3248 17.5564 14.9565 17.3667L8.35653 13.9667C7.98831 13.777 7.84358 13.3248 8.03327 12.9565Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_star">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.993 1.25201C12.2785 1.25201 12.5392 1.41409 12.6656 1.67009L15.577 7.56935L22.0866 8.51277C22.3693 8.55374 22.6041 8.75171 22.6923 9.02337C22.7806 9.29503 22.7068 9.59322 22.5021 9.79244L17.7849 14.3842L18.8972 20.8682C18.9455 21.1497 18.8297 21.4342 18.5986 21.602C18.3674 21.7698 18.061 21.7917 17.8084 21.6585L11.9996 18.5976L6.17703 21.6589C5.92434 21.7917 5.61814 21.7696 5.38717 21.6018C5.15621 21.434 5.04054 21.1496 5.08881 20.8682L6.20108 14.3842L1.48388 9.79244C1.2792 9.59322 1.20546 9.29503 1.29368 9.02337C1.3819 8.75171 1.61677 8.55374 1.89944 8.51277L8.40904 7.56935L11.3205 1.67009C11.4468 1.41409 11.7075 1.25201 11.993 1.25201ZM11.993 3.6967L9.57956 8.58694C9.47027 8.80838 9.25898 8.96184 9.01458 8.99726L3.62057 9.779L7.53014 13.5846C7.70717 13.7569 7.78798 14.0053 7.74621 14.2488L6.82416 19.6239L11.651 17.0862C11.8697 16.9712 12.131 16.9713 12.3497 17.0865L17.1616 19.6222L16.2398 14.2488C16.198 14.0053 16.2789 13.7569 16.4559 13.5846L20.3655 9.779L14.9714 8.99726C14.727 8.96184 14.5157 8.80838 14.4065 8.58694L11.993 3.6967Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_time">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V11.6893L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7626 15.8232 14.4697 15.5303L11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12V7C11.25 6.58579 11.5858 6.25 12 6.25Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_twitter">
                        <path d="M19 6.08279C18.25 6.44959 17.515 6.59855 16.75 6.82387C15.9092 5.87694 14.6627 5.82454 13.465 6.27218C12.2672 6.71982 11.4827 7.81422 11.5 9.06956V9.81812C9.06625 9.88025 6.89875 8.77387 5.5 6.82387C5.5 6.82387 2.3635 12.3879 8.5 15.0581C7.096 15.9915 5.69575 16.6211 4 16.5552C6.481 17.9048 9.18475 18.369 11.5255 17.6908C14.2105 16.9123 16.417 14.9039 17.2637 11.8954C17.5163 10.9804 17.6418 10.0351 17.6365 9.08603C17.635 8.89963 18.769 7.01101 19 6.08204V6.08279Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_call">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5 4.75C4.66848 4.75 4.35054 4.8817 4.11612 5.11612C3.8865 5.34573 3.75545 5.65547 3.75017 5.97962C3.98167 9.68818 5.5593 13.185 8.18716 15.8128C10.815 18.4407 14.3118 20.0183 18.0204 20.2498C18.3445 20.2446 18.6543 20.1135 18.8839 19.8839C19.1183 19.6495 19.25 19.3315 19.25 19V15.5078L15.3145 13.9336L14.1431 15.8859C13.9423 16.2206 13.5183 16.3453 13.1683 16.1726C10.8487 15.0287 8.97133 13.1513 7.82735 10.8317C7.65471 10.4817 7.77943 10.0577 8.11413 9.85688L10.0664 8.6855L8.49223 4.75H5ZM3.05546 4.05546C3.57118 3.53973 4.27065 3.25 5 3.25H9C9.30668 3.25 9.58246 3.43671 9.69636 3.72146L11.6964 8.72146C11.8331 9.06324 11.7015 9.45373 11.3859 9.64312L9.49809 10.7758C10.3941 12.3212 11.6788 13.6059 13.2242 14.5019L14.3569 12.6141C14.5463 12.2985 14.9368 12.1669 15.2785 12.3036L20.2785 14.3036C20.5633 14.4175 20.75 14.6933 20.75 15V19C20.75 19.7293 20.4603 20.4288 19.9445 20.9445C19.4288 21.4603 18.7293 21.75 18 21.75C17.9848 21.75 17.9697 21.7495 17.9545 21.7486C13.8709 21.5005 10.0194 19.7664 7.1265 16.8735C4.23364 13.9806 2.49954 10.1291 2.25138 6.04549C2.25046 6.03035 2.25 6.01518 2.25 6C2.25 5.27065 2.53973 4.57118 3.05546 4.05546Z" fill="currentColor"></path>
                    </symbol>
                    <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ic_camera">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.75 4.5C8.6837 4.5 8.62011 4.52634 8.57322 4.57322C8.52634 4.62011 8.5 4.6837 8.5 4.75C8.5 5.47935 8.21027 6.17882 7.69454 6.69454C7.17882 7.21027 6.47935 7.5 5.75 7.5H4.75C4.41848 7.5 4.10054 7.6317 3.86612 7.86612C3.6317 8.10054 3.5 8.41848 3.5 8.75V17.75C3.5 18.0815 3.6317 18.3995 3.86612 18.6339C4.10054 18.8683 4.41848 19 4.75 19H18.75C19.0815 19 19.3995 18.8683 19.6339 18.6339C19.8683 18.3995 20 18.0815 20 17.75V8.75C20 8.41848 19.8683 8.10054 19.6339 7.86612C19.3995 7.6317 19.0815 7.5 18.75 7.5H17.75C17.0207 7.5 16.3212 7.21027 15.8055 6.69454C15.2897 6.17882 15 5.47935 15 4.75C15 4.6837 14.9737 4.62011 14.9268 4.57322C14.8799 4.52634 14.8163 4.5 14.75 4.5H8.75ZM4.75 6C4.02065 6 3.32118 6.28973 2.80546 6.80546C2.28973 7.32118 2 8.02065 2 8.75V17.75C2 18.4793 2.28973 19.1788 2.80546 19.6945C3.32118 20.2103 4.02065 20.5 4.75 20.5H18.75C19.4793 20.5 20.1788 20.2103 20.6945 19.6945C21.2103 19.1788 21.5 18.4793 21.5 17.75V8.75C21.5 8.02065 21.2103 7.32118 20.6945 6.80546C20.1788 6.28973 19.4793 6 18.75 6H17.75C17.4185 6 17.1005 5.8683 16.8661 5.63388C16.6317 5.39946 16.5 5.08152 16.5 4.75C16.5 4.28587 16.3156 3.84075 15.9874 3.51256C15.6592 3.18438 15.2141 3 14.75 3H8.75C8.28587 3 7.84075 3.18438 7.51256 3.51256C7.18437 3.84075 7 4.28587 7 4.75C7 5.08152 6.8683 5.39946 6.63388 5.63388C6.39946 5.8683 6.08152 6 5.75 6H4.75Z" fill="currentColor"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.75 10.5C10.5074 10.5 9.5 11.5074 9.5 12.75C9.5 13.9926 10.5074 15 11.75 15C12.9926 15 14 13.9926 14 12.75C14 11.5074 12.9926 10.5 11.75 10.5ZM8 12.75C8 10.6789 9.67893 9 11.75 9C13.8211 9 15.5 10.6789 15.5 12.75C15.5 14.8211 13.8211 16.5 11.75 16.5C9.67893 16.5 8 14.8211 8 12.75Z" fill="currentColor"></path>
                    </symbol>
                </svg>

                <svg className="ic">
                    <use xlinkHref="#ic_camera_plus"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_caret_down"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_check"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_chevron_down"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_chevron_left"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_chevron_right"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_circle"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_circle_radio"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_date"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_document"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_facebook"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_fill_star"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_insta"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_left"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_linkedin"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_location"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_mail"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_right"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_search"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_share"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_star"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_time"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_twitter"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_call"></use>
                </svg>
                <svg className="ic">
                    <use xlinkHref="#ic_camera"></use>
                </svg>

            </div>
        </>
    )
}

export default SvgSprite
