import React from "react";
import { Col, Container, Row } from "reactstrap";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import MasterLayout from "../../../layout/masterlayout";

const StoryAllyBabu = (props: any) => {
  return (
    <>
      <section className="bg-story-detail story-detail-banner pt-50px">
        <Container>
          <Row>
            <Col md="4" className="text-md-center">
              <img
                src={toAbsoluteUrl("/media/images/thumb_AllyBabu.png")}
                alt=""
              />
            </Col>

            <Col md="7">
              <div>
                <h1 className="mb-10px font-38">
                  They are very honest with great sales representatives!
                </h1>
                <p className="mb-10px">
                  I started buying from them when their company was just
                  starting, and we have been good partners since then.
                </p>
                <p className="mb-10px">20 Jan, 2023</p>
                <div>
                  <h3 className="mb-10px font-20">
                    Ally Babu -{" "}
                    <span className="inter font-16 fw-400">
                      Owner Babu Ally Grocery Shop
                    </span>
                  </h3>
                </div>
                <p className="mb-10px">Bagamoyo - Pwani</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* story content */}
      <section className="pt-50px pb-50px">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p className="fw-600">
                Meet Ally, a grocery shop owner located at Bagamoyo, Pwani.
              </p>
              <p className="fw-600">
                He has been in business for 20 years now and was one among our
                very first customers when the company had just been started back
                in 2013 and has remained loyal since then.
              </p>
              <p>
                Babu Ally buys two products from EA Foods, Potato and Onion King
                and he resells them in both wholesale and retail. His order
                volumes change from time to time depending on the season but
                never below 30 bags of onions per each order.
              </p>
              <p className="fw-600">
                “I started buying from EA Foods when their company had just
                started. One thing I love about EA Foods is that they are very
                honest, truthful and have great sales representatives who are
                very understanding”.
              </p>
              <p>
                Babu Ally remains loyal to our two brands Potato and Onion King
                and looks forward to adding new products from the list of our
                brands.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(StoryAllyBabu);
