import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { metaImage } from "../../../helper/AssetHelpers";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";

const PrivacyPolicy = (props: any) => {
  useEffect(() => {
    goToTop();
  }, []);

  return (
    <>
      <CustomHelmet
        title={`Privacy Policy | EA Foods`}
        metaDescription={`At EA Foods, we take the security of your data seriously, read our privacy policy for further information.`}
        imagePath={metaImage}
      />
      <section className="section-padding bg-light">
        <Container>
          <Row className="g-0 justify-content-center">
            <Col md="auto">
              <h2>Privacy Policy</h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-50px section-padding pt-0">
        <Container>
          <Row className="">
            <Col md="12">
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  1. What is the EA Foods Platform?
                </h6>
                <p className="mb-20px">Welcome to eafoods.</p>
                <p className="mb-20px">
                  eafoods (“us”, “we”, or “our”) operates www.eafoods.com
                  (hereinafter referred to as “Service”).
                </p>
                <p className="mb-20px">
                  Our Privacy Policy governs your visit to www.eafoods.com, and
                  explains how we collect, safeguard and disclose information
                  that results from your use of our Service.
                </p>
                <p>
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. Unless otherwise defined in this
                  Privacy Policy, the terms used in this Privacy Policy have the
                  same meanings as in our Terms and Conditions.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  2. What is this Privacy Policy?
                </h6>
                <p className="mb-20px">
                  EA Foods is committed to protecting the privacy of our website
                  users. This Privacy Policy sets out how we collect, use,
                  disclose, and protect personal information in accordance with
                  the applicable privacy laws.
                </p>
                <p className="mb-20px">
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. Unless otherwise defined in this
                  Privacy Policy, the terms used in this Privacy Policy have the
                  same meanings as in our Terms and Conditions.
                </p>
                <p>
                  Our Terms and Conditions (“Terms”) govern all use of our
                  Service and together with the Privacy Policy constitutes your
                  agreement with us (“agreement”).
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  3. Why does the Company have it?
                </h6>
                <p className="mb-20px">
                  We believe that protecting the privacy of our website users is
                  of utmost importance. Our Privacy Policy sets out the ways in
                  which we collect, use, and protect the personal information of
                  our users, in accordance with the applicable privacy laws. By
                  having a Privacy Policy, we aim to give our users confidence
                  and peace of mind that their personal information is being
                  handled responsibly and securely.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  4. What is Sensitive Personal Data or Information?
                </h6>
                <p className="mb-20px">
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you (“Personal Data”). Personally
                  identifiable information may include, but is not limited to:
                </p>
                <ul className="mb-20px">
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>
                    Address, Country, State, Province, ZIP/Postal code, City
                  </li>
                  <li>Cookies and Usage Data</li>
                </ul>
                <p>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from us by following the
                  unsubscribe link.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  5. When does the company collect user Information?
                </h6>
                <p className="mb-20px">
                  EA Foods collects user information on our website in order to
                  provide and improve our services, to respond to user requests,
                  and to communicate with our users. Some examples of when we
                  collect user information include when:
                </p>
                <ol className="mb-20px">
                  <li>Users create an account on our website</li>
                  <li>Users make a purchase on our website</li>
                  <li>
                    Users sign up to receive our newsletter or promotional
                    material
                  </li>
                  <li>
                    Users communicate with us through our contact form or
                    through email
                  </li>
                </ol>
                <p className="mb-20px">
                  When we collect user information, we ask for information such
                  as name, email address, postal address, and phone number. The
                  information we collect is used to personalise the user's
                  experience, to process transactions, and to communicate with
                  our users.
                </p>
                <p>
                  We understand the importance of privacy and security, and we
                  take steps to ensure that the user information we collect is
                  protected against unauthorised access, use, disclosure,
                  alteration, or destruction. Our Privacy Policy outlines the
                  measures we take to protect user information, as well as the
                  ways in which we may use the information we collect.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  6. Who access to your Information?
                </h6>
                <p className="mb-20px">
                  We may disclose personal information that we collect, or you
                  provide:
                </p>
                <p className="mb-10px">
                  <b>- Business Transaction.</b>
                </p>
                <p className="mb-20px">
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </p>
                <p className="mb-10px">
                  <b>- Other cases. We may disclose your information also:</b>
                </p>
                <ul className="mb-20px">
                  <li>To our subsidiaries and affiliates;</li>
                  <li>
                    To contractors, service providers, and other third parties
                    we use to support our business;
                  </li>
                  <li> To fulfill the purpose for which you provide it;</li>
                  <li>
                    For the purpose of including your company’s logo on our
                    website;
                  </li>
                  <li>
                    For any other purpose disclosed by us when you provide the
                    information;
                  </li>
                  <li>With your consent in any other cases;</li>
                  <li>
                    If we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of the Company, our
                    customers, or others.
                  </li>
                </ul>

                <p>
                  These third parties are required to keep your information
                  confidential and to use it only for the purposes for which it
                  was disclosed.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  7. How do we use your information?
                </h6>
                <p className="mb-20px">
                  We use the information we collect to provide and improve our
                  website, to respond to your requests, to send you promotional
                  material, and to communicate with you. We may also use your
                  information to comply with legal obligations, to enforce our
                  policies, and to protect the rights and safety of EA Foods,
                  our users, and others.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  8. How do we protect your Information?
                </h6>
                <p className="mb-20px">
                  We take reasonable security measures to protect your
                  information from unauthorized access, use, disclosure,
                  alteration, or destruction. However, no method of transmission
                  over the Internet or method of electronic storage is 100%
                  secure. While we strive to use commercially acceptable means
                  to protect your Personal Data, we cannot guarantee its
                  absolute security.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">9. Withdrawal of User Consent</h6>
                <p className="mb-20px">
                  If you have shared content on our website and would like to
                  withdraw it, you may contact us at info@eafoods.com and
                  request that your content be removed. We will make reasonable
                  efforts to remove the content as soon as possible, subject to
                  any legal or technical constraints.
                </p>
                <p>
                  Please note that once content has been shared, it may have
                  been copied or shared by others, and it may be difficult to
                  remove all copies of the content from the internet. We cannot
                  guarantee that all copies of the content will be removed.
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="mb-10 fw-700">
                  10. Users Right to Review and Modify User Information
                </h6>
                <p className="mb-20px">
                  You have the right to access, correct, and delete your
                  personal information, and to control how we use it. If you
                  would like to update or remove your personal information, you
                  may do so by logging into your account and updating your
                  information, or by contacting us at info@eafoods.com.
                </p>
                <p>
                  Please note that we may retain some information as required by
                  law or for legitimate business purposes.
                </p>
              </div>
              <div>
                <h6 className="mb-10 fw-700">11. Changes in Privacy Policy</h6>
                <p className="mb-0">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or to comply with legal requirements.
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(PrivacyPolicy);
