import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Row,
} from "reactstrap";
import { goToTop } from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { SINGLE_PRODUCT } from "../../../_eaFruitsCms/https/ApiRoutes";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";

const ProductDetail = (props: any) => {
  const navigate = useNavigate();
  let { productSlug } = useParams();
  const productId = localStorage.getItem("productId");

  const [vSelected, setVSelected] = useState<any>("");
  const [selected, setSelected] = useState<any>("");
  const [variantSizes, setVariantSizes] = useState<any>([]);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [open, setOpen] = useState("1");
  const [products, setProducts] = useState<any>([]);
  const [imageLoad, setImageLoad] = useState<boolean>(false);

  const toggleProductDetail: any = (id: any) => {
    if (open === id) {
      setOpen("0");
    } else {
      setOpen("1");
    }
    return;
  };

  useEffect(() => {
    goToTop(setDisplayLoader);
  }, []);

  useEffect(() => {
    fetchProductDetail().then();
  }, []);

  const fetchProductDetail = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      SINGLE_PRODUCT + `${productSlug}`,
      navigate,
      false
    );
    setProducts(response);
    setDisplayLoader(false);
  };

  return (
    <>
      <CustomHelmet
        title={`${products && products.product_name} | EA Foods`}
        metaDescription={`Buy ${
          products && products.product_name
        } at East Africa Foods Co.`}
        imagePath={products.cover_image}
      />
      {displayLoader && <CustomLoader />}
      <section className="section-padding">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7">
              <div className="position-relative flex-grow-1">
                <img
                  src={toAbsoluteUrl(products.cover_image)}
                  onLoad={() => {
                    setImageLoad(true);
                  }}
                  alt=""
                  className="rounded img-fluid"
                />
                {!imageLoad && <span className="skeleton-box"></span>}
              </div>
            </Col>
            <Col lg="5">
              <h1 className="font-38 mb-20px mt-4 mt-md-0">
                {products.product_name && products.product_name}
              </h1>
              <div className="font-16 fw-600 mb-10">Available Variant(s)</div>
              <div className="btngrp-space mb-10px">
                {products.variants &&
                  products.variants.map((item: any, index: number) => {
                    return (
                      <>
                        <Button
                          color="primary"
                          outline
                          onClick={() => {
                            setVSelected(index);
                            setSelected(true);
                            setVariantSizes(item.variants_size || []);
                          }}
                          active={vSelected === index}
                        >
                          {item.variants_name}
                        </Button>
                      </>
                    );
                  })}
              </div>
              {selected === true && variantSizes.length > 0 ? (
                <>
                  <div className="font-16 fw-600 mb-10">
                    Available in Sizes(s)
                  </div>
                  <div className="btngrp-space">
                    {variantSizes.map((item: any) => {
                      return (
                        <>
                          <Button
                            disabled={true}
                            color="primary"
                            outline
                            style={{ opacity: 1 }}
                          >
                            {item} KG
                          </Button>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}

              <hr />
              <Accordion
                flush
                open={open}
                className="ea-accordion"
                //@ts-ignore
                toggle={toggleProductDetail}
              >
                <AccordionItem>
                  <AccordionHeader targetId="1" tag={"h3"}>
                    Product Details
                  </AccordionHeader>
                  <AccordionBody accordionId="1" className="px-0">
                    {!["", null, "null"].includes(products.description) ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: products.description,
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <hr />
              <Button
                color="primary"
                outline
                className="flex-grow-1 w-100 d-flex align-items-center justify-content-center"
                onClick={() => navigate("/contact-us")}
              >
                <KTSVG path="/media/icons/ic_call.svg" className="ic me-2" />
                Contact our Customer Support
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(ProductDetail);
