import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { Col, Container, Row } from "reactstrap";
import {
  dateFormat,
  goToTop,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { SINGLE_STORIES } from "../../../_eaFruitsCms/https/ApiRoutes";
import { KTSVG } from "../../../helper/components/KTSVG";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";

const StoryDetail = (props: any) => {
  const navigate = useNavigate();
  const currentURL = window.location.href;

  const { farmerStorySlug } = useParams();

  const [stories, setStories] = useState<any>([]);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);

  useEffect(() => {
    fetchPostsDetail().then();
  }, [stories.description]);

  useEffect(() => {
    goToTop(setDisplayLoader);
  }, []);

  const fetchPostsDetail = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      SINGLE_STORIES + `/${farmerStorySlug}`,
      navigate,
      false
    );
    setDisplayLoader(false);
    setStories(response);
  };

  return (
    <>
      <CustomHelmet
        title={`${stories && stories.story_title} | EA Foods`}
        metaDescription={`${stories && stories.story_title} | EA Foods`}
        imagePath={stories.profile_picture}
      />
      {displayLoader && <CustomLoader />}
      <section className="bg-story-detail story-detail-banner">
        <Container>
          <Row className="justify-content-center align-items-center pt-50px">
            <Col md="3" className="text-md-center pb-50px ">
              {/* <img src="https://dummyimage.com/280x360/ddd/000" alt="" /> */}
              <img
                src={stories.profile_picture}
                alt=""
                className="img-fluid story-picture"
              />
            </Col>
            <Col md="6">
              <div className="pb-50px">
                <h1 className="mb-10px font-38">{stories.story_title}</h1>
                <p className="mb-10px">{stories.story_short_description}</p>
                <p className="mb-10px">
                  {" "}
                  {dateFormat(stories.publish_date).displayDate}
                </p>
                <div>
                  <h3 className="mb-10px font-20">
                    {stories.firstname} {stories.lastname} -{" "}
                    <span className="inter font-16 fw-400">
                      {stories.subtitle}
                    </span>
                  </h3>
                </div>
                <p className="mb-10px">{stories.location_text}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* story content */}
      <section className="pt-50px pb-50px">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <div
                dangerouslySetInnerHTML={{ __html: stories.story_description }}
              />
            </Col>
          </Row>
          <Row className="mb-4 justify-content-center">
            <Col md="10" lg="9" xl="8">
              <hr></hr>
              <Row>
                <Col sm="6" className="mb-3 mb-md-0"></Col>
                <Col className="text-md-end social" sm="6">
                  <div className="social d-flex align-items-center justify-content-sm-end">
                    <span className="fw-600">Share this on</span>
                    <ul className="text-white list-unstyled mb-0 d-inline-flex ms-3">
                      <li className="me-3">
                        <FacebookShareButton
                          quote={`${stories && stories.story_title} | EA Foods`}
                          url={currentURL}
                          title={`${stories && stories.story_title} | EA Foods`}
                        >
                          <KTSVG
                            path="/media/icons/ic_facebook.svg"
                            className="ic text-primary"
                          />
                        </FacebookShareButton>
                      </li>
                      <li className="me-3">
                        <TwitterShareButton
                          title={`${stories && stories.story_title} | EA Foods`}
                          url={currentURL}
                        >
                          <KTSVG
                            path="/media/icons/ic_twitter.svg"
                            className="ic text-primary"
                          />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          title={`${stories && stories.story_title} | EA Foods`}
                          summary={`${
                            stories && stories.story_title
                          } | EA Foods`}
                          url={currentURL}
                        >
                          <KTSVG
                            path="/media/icons/ic_linkedin.svg"
                            className="ic text-primary"
                          />
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="section-padding pt-0">
        <img
          src={toAbsoluteUrl("/media/images/storyimage.jpg")}
          srcSet={toAbsoluteUrl("/media/images/storyimage@2x.jpg 2x")}
          alt=""
          className="mb-30px"
        />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p className="mb-0">
                Joyce’s endeavour to better her life is just getting better each
                day, she has currently employed assistants to help her with the
                business and her order delivery has increased from 20 trunks in
                a week to 25 trunks after every 3 days!
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default MasterLayout(StoryDetail);
