const API_URL = process.env.REACT_APP_API_BASE_URL

export const BLOG_CATEGORY_LIST = API_URL  +  `website/blog/categories/list`
export const BLOG_POST_LIST = API_URL  +  `website/blog/post/list`
export const BLOG_STORIES_LIST = API_URL  +  `website/sucess-stories/get`
export const BLOG_SINGLE_POSTS = API_URL  +  `website/blog/post/get`
export const TESTIMONIALS_LIST = API_URL  +  `website/testimonial` 
export const PRODUCT_GROUP_LIST = API_URL  +  `website/webproductgroup/get`
export const BRAND_LIST = API_URL  +  `website/productbrand/get`
export const CATEGORIES_LIST = API_URL  +  `website/productcategory/get`
export const SINGLE_STORIES = API_URL  +  `website/sucess-stories`
export const SINGLE_BRANDS = API_URL  +  `website/productbrand`
export const APPLICATION = API_URL  +  `website/Application/create`
export const CURRENT_OPENING = API_URL  +  `website/CurrentOpenings/get`
export const SINGLE_CURRENT_OPENING = API_URL  +  `website/CurrentOpenings/`
export const INQUIRES = API_URL  +  `website/inquiries/`
export const SINGLE_PRODUCT = API_URL  +  `website/webproduct/`
