import React from "react";
import { Col, Container, Row } from "reactstrap";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import MasterLayout from "../../../layout/masterlayout";

const StoryExaveryKilumile = (props: any) => {
  return (
    <>
      <section className="bg-story-detail story-detail-banner pt-50px">
        <Container>
          <Row>
            <Col md="4" className="text-md-center">
              <img
                src={toAbsoluteUrl("/media/images/thumb_ExaveryKilumile.png")}
                alt=""
              />
            </Col>
            <Col md="7">
              <div>
                <h1 className="mb-10px font-38">
                  I have been able to boost up my yields thanks to the training
                  provided by the company through demonstration farms!
                </h1>
                <p className="mb-10px">
                  The middlemen who used to cause us so much loss have
                  disappeared.
                </p>
                <p className="mb-10px">13 Jan, 2023</p>
                <div>
                  <h3 className="mb-10px font-20">
                    Exavery Kilumile -{" "}
                    <span className="inter font-16 fw-400">Potato Farmer</span>
                  </h3>
                </div>
                <p className="mb-10px">Luhololo - Njombe</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* story content */}
      <section className="pt-50px pb-50px">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p>
                Meet Exavery, one of our potato farmers located at Lusitu in
                Njombe region who has been growing potatoes since 1999. He is
                one of the members in demonstration farming groups created by EA
                Foods in cooperation with other companies such as CRAFT and has
                been working with EA Foods for 2 years now.
              </p>
              <p>
                At first, he was a farmer like many others whom the company
                bought potatoes from, later on, the company invited him to join
                the sustainable agriculture training through demonstration
                farms.
              </p>

              <p>
                Before partnering with EA Foods, Exavery used to harvest only 60
                bags per 15 acres but now harvests an average of 130 - 150 bags
                daily, each weighing 85-100kgs.
              </p>

              <p className="fw-600">
                “I have been growing potatoes since 1999 using old farming
                methods, I used to get low yields but within just 2 years of
                working with EA Foods, I have been able to boost up my yields
                thanks to the training provided by the company through
                demonstration farms, the middlemen who used to cause us so much
                loss have disappeared”.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding pt-0">
        <img
          src={toAbsoluteUrl("/media/images/exaveryKilumile.png")}
          alt=""
          className="mb-30px img-fluid w-100"
        />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p>
                Through the profit made, he has managed to expand and buy 10
                acres of land ready for farming, build 2 modern houses, afford
                his children’s school fees and indulge in farming activities for
                other crops such as maize and avocado and looks forward to tree
                crop farming in the coming days.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(StoryExaveryKilumile);
