import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  goToTop,
  MOBILE_MAX_LENGTH,
  successToast,
} from "../../_eaFruitsCms/helpers/CommonHelpers";
import { postRequest } from "../../_eaFruitsCms/https/apiCall";
import { INQUIRES } from "../../_eaFruitsCms/https/ApiRoutes";
import ErrorHandler from "../../_eaFruitsCms/validation/ErrorHandler";
import { validateEmail } from "../../_eaFruitsCms/validation/validation";
import { metaImage, toAbsoluteUrl } from "../../helper/AssetHelpers";
import SectionHeadingStart from "../../layout/components/headings/sectionHeadingStart";
import MasterLayout from "../../layout/masterlayout";
import CustomHelmet from "../components/CustomHelmet";
import CustomReCaptcha from "../components/CustomReCaptcha";

const ContactUs = () => {
  const navigate = useNavigate();

  const [captchaValid, setCaptchaValid] = useState<boolean>(false);
  const [captchaReset, setCaptchaReset] = useState<boolean>(false);

  const initialRow: any = {
    inquiry_type: "General",
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    inquiry_subject: "",
    inquiry_message: "",
  };

  const initialValidation: any = {
    inquiry_type: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    inquiry_subject: "",
    inquiry_message: "",
  };

  const [inquiresValidation, setInquiresValidation] =
    useState<any>(initialValidation);
  const [inquires, setInquires] = useState<any>(initialRow);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonText, setButtonText] = useState("Submit");

  useEffect(() => {
    goToTop();
  }, []);

  const handleChange = (e: any, name: string) => {
    const inputName = e.target ? e.target.name : name;
    let inputValue = e.target ? e.target.value : e.value;

    setInquiresValidation({ ...inquiresValidation, [inputName]: "" });

    switch (inputName) {
      case "first_name":
        setInquires({
          ...inquires,
          ["first_name"]: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "last_name":
        setInquires({
          ...inquires,
          ["last_name"]: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
        });
        break;
      case "email":
        setInquires({ ...inquires, ["email"]: inputValue });
        setErrorMessage("");
        break;
      case "mobile_number":
        const re = /^[0-9\b]+$/;
        if (inputValue === "" || re.test(inputValue)) {
          setInquires({ ...inquires, ["mobile_number"]: e.target.value });
        }
        break;
      case "inquiry_subject":
        setInquires({ ...inquires, ["inquiry_subject"]: inputValue });
        break;
      case "inquiry_message":
        setInquires({ ...inquires, ["inquiry_message"]: inputValue });
        console.log("details-resume", e.target.files);
        break;
    }
  };

  const validateForm = () => {
    let validations = { ...inquiresValidation };
    let isValid = true;

    if (inquires.first_name === "") {
      validations.first_name = "is-invalid";
      isValid = false;
    }

    if (inquires.last_name === "") {
      validations.last_name = "is-invalid";
      isValid = false;
    }

    if (inquires.email === "") {
      validations.email = "is-invalid";
      isValid = false;
    } else {
      if (!validateEmail(inquires.email)) {
        setInquiresValidation({
          ...inquiresValidation,
          ["email"]: "is-invalid",
        });
        isValid = false;
      }
    }

    if (inquires.mobile_number === "") {
      validations.mobile_number = "is-invalid";
      isValid = false;
    }

    if (inquires.inquiry_subject === "") {
      validations.inquiry_subject = "is-invalid";
      isValid = false;
    }

    if (inquires.inquiry_message === "") {
      validations.inquiry_message = "is-invalid";
      isValid = false;
    }

    if (!isValid) {
      setCaptchaReset(!captchaReset);
    }

    setInquiresValidation(validations);
    return isValid;
  };

  const submitForm = () => {
    const isValid = validateForm();
    if (isValid) {
      let InquiresData = new FormData();
      const detailsInfo = { ...inquires };

      Object.entries(detailsInfo).map((object: any) => {
        return InquiresData.append(object[0], object[1]);
      });

      setButtonText("Please Wait...");

      postRequest(INQUIRES, InquiresData, navigate, false).then((res: any) => {
        if (res.status === 201) {
          {
            setInquires(initialRow);
            successToast("Your Inquiry has been submitted successfully!");
            setErrorMessage("");
          }
        } else {
          setErrorMessage("Inquiry with this email already exists.");
          // errorToast("Inquiries with this email already exists.");
        }
        setCaptchaReset(!captchaReset);
        setButtonText("Submit");
      });
    }
  };

  return (
    <>
      <CustomHelmet></CustomHelmet>
      <CustomHelmet
        title={`Contact Us - Customer Care Info| EA Foods`}
        metaDescription={`Contact EA foods customer care service for queries or questions about our products and services.`}
        imagePath={metaImage}
      />
      {/* <HeroBanner
        heroTitle="Contact Us"
        heroDesc="Be a part of something big! We have Friendly and supportive work environment."
        isButton={false}
        path="/media/illustrations/heroContact.svg"
      /> */}

      <section className="contact-hero-banner">
        <Container className="h-100" fluid="xl">
          <div className="position-relative h-100">
            <Row className="align-items-center h-100">
              <Col md={6} lg={5}>
                <div className="py-4">
                  <h1 className="herotitle">Contact Us</h1>
                  <p>
                    Be a part of something big! We have Friendly and supportive
                    work environment.
                  </p>
                </div>
                {/* <img src={toAbsoluteUrl('/media/illustrations/heroBgVector.svg')} alt="" className='floating animated-shape' /> */}
              </Col>
              <Col md={6} lg={5} className="offset-lg-2">
                <div className="text-lg-end">
                  <img
                    src={toAbsoluteUrl("/media/illustrations/heroContact.svg")}
                    alt=""
                    className="img-fluid hero-vector"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="contact-section section-padding">
        <Container className="container-xl" fluid>
          <Row className="justify-content-between">
            <Col md="6" lg="6">
              <SectionHeadingStart sectionTitle="Get in Touch" />
              <Form>
                <Row>
                  <Col sm="6" md="12" lg="6">
                    <FormGroup>
                      <Label for="fname">
                        First Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="fname"
                        name="first_name"
                        placeholder="Type here..."
                        type="text"
                        className={clsx(
                          "form-control",
                          inquiresValidation.first_name
                        )}
                        onChange={(e) => handleChange(e, "first_name")}
                        value={inquires.first_name}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="12" lg="6">
                    <FormGroup>
                      <Label for="lname">
                        Last Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="lname"
                        name="last_name"
                        placeholder="Type here..."
                        type="text"
                        className={clsx(
                          "form-control",
                          inquiresValidation.last_name
                        )}
                        onChange={(e) => handleChange(e, "last_name")}
                        value={inquires.last_name}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="12" lg="6">
                    <FormGroup>
                      <Label for="phone">
                        Phone Number<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="phone"
                        maxLength={MOBILE_MAX_LENGTH}
                        name="mobile_number"
                        placeholder="Type here..."
                        type="text"
                        className={clsx(
                          "form-control",
                          inquiresValidation.mobile_number
                        )}
                        onChange={(e) => handleChange(e, "mobile_number")}
                        value={inquires.mobile_number}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="12" lg="6">
                    <FormGroup>
                      <Label for="email">
                        Email<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="email"
                        name="email"
                        placeholder="Type here..."
                        type="text"
                        className={clsx(
                          "form-control",
                          inquiresValidation.email
                        )}
                        onBlur={(e) => {
                          if (!validateEmail(e.target.value)) {
                            setInquiresValidation({
                              ...inquiresValidation,
                              ["email"]: "is-invalid",
                            });
                          }
                        }}
                        onChange={(e) => handleChange(e, "email")}
                        value={inquires.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label for="subject">
                        Subject<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="subject"
                        name="inquiry_subject"
                        placeholder="Type here..."
                        type="text"
                        className={clsx(
                          "form-control",
                          inquiresValidation.inquiry_subject
                        )}
                        onChange={(e) => handleChange(e, "inquiry_subject")}
                        value={inquires.inquiry_subject}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label for="msg">
                        Message<span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="msg"
                        name="inquiry_message"
                        placeholder="Comment"
                        type="textarea"
                        rows={4}
                        className={clsx(
                          "form-control",
                          inquiresValidation.inquiry_message
                        )}
                        onChange={(e) => handleChange(e, "inquiry_message")}
                        value={inquires.inquiry_message}
                      />
                    </FormGroup>
                  </Col>
                  <div className="mb-3">
                    <ErrorHandler errorMessage={errorMessage} />
                  </div>
                </Row>{" "}
                <CustomReCaptcha
                  setCaptchaValid={setCaptchaValid}
                  captchaReset={captchaReset}
                />
                <Button
                  className="btn-rounded"
                  color="primary"
                  outline
                  disabled={!captchaValid || buttonText !== "Submit"}
                  onClick={submitForm}
                >
                  {buttonText}
                </Button>
              </Form>
            </Col>
            <Col md="6" lg="5" xxl="4" className="offset-lg-1 mt-5 mt-md-0">
              <SectionHeadingStart sectionTitle="Contact Information" />
              <div className="mb-20px">
                <h6 className="fw-700">Address:</h6>
                <p>
                  Bunju B, Kilimani Road <br />
                  Dar es Salaam
                  <br />
                  Tanzania
                </p>
              </div>
              <div className="mb-20px">
                <h6 className="fw-700">Phone Number:</h6>
                <a
                  href="callto:+255 672 22 77 77"
                  className="text-dark text-hover-primary"
                >
                  +255-672-227-777
                </a>
              </div>
              <div className="mb-20px">
                <h6 className="fw-700">Email:</h6>
                <a
                  href="mailto:info@eafruits.com"
                  className="text-dark text-hover-primary"
                >
                  info@eafoods.com
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(ContactUs);
