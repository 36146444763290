import { useEffect, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"

const CustomReCaptcha = ({setCaptchaValid, captchaReset}: any) => {
    const key: any = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    const captchaRef: any = useRef(null)

    useEffect(() => {
        //To make sure Google reCAPTCHA reset every time
        captchaRef.current.reset()
    }, [captchaReset]);

    const handleChange = (value: any) => {
        setCaptchaValid(false)

        if (value !== "") {
            setCaptchaValid(true)
        }
    }

    return <>
        <div className="mb-3">
            <ReCAPTCHA
                sitekey={key}
                onChange={handleChange}
                ref={captchaRef}
            />
        </div>
    </>
}

export default CustomReCaptcha
