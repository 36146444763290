import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import {
  dateFormat,
  goToTop,
  nextOffset,
} from "../../../_eaFruitsCms/helpers/CommonHelpers";
import { getRequest } from "../../../_eaFruitsCms/https/apiCall";
import { BLOG_STORIES_LIST } from "../../../_eaFruitsCms/https/ApiRoutes";
import { metaImage } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";
import NoRecords from "../../../layout/components/noRecords/noRecords";
import MasterLayout from "../../../layout/masterlayout";
import CustomHelmet from "../../components/CustomHelmet";
import CustomLoader from "../../components/CustomLoader";
import StoryCard from "./components/storyCard";
import StoryHeader from "./components/storyHeader";

const EmployeeStories = (props: any) => {
  const navigate = useNavigate();

  const [stories, setStories] = useState<any>([]);
  const [limit, setLimit] = useState<any>(6);
  const [count, setCount] = useState<any>("");
  const [search, setSearch] = useState<any>("");
  const [postsOrdering, setPostsOrdering] = useState<any>("-created_at");
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [currentOffset, setCurrentOffSet] = useState<any>("");
  const [nextUrl, setNextUrl] = useState<any>("");

  useEffect(() => {
    setSearch("");
    setTimeout(() => {
      blogsStoriesList().then();
    }, 500);
  }, [search]);

  useEffect(() => {
    blogsStoriesList().then();
  }, [limit, postsOrdering]);

  useEffect(() => {
    goToTop(setDisplayLoader);
  }, []);

  const blogsStoriesList = async () => {
    setDisplayLoader(true);
    const response = await getRequest(
      BLOG_STORIES_LIST +
        `?limit=${limit}&usertype=Employee&search=${search}&ordering=${postsOrdering}&offset=${currentOffset}`,
      navigate,
      false
    );

    setLoadMore(false);

    if (response.next && !["", null, "null"].includes(response.next)) {
      setLoadMore(true);
      setNextUrl(response.next);
    }

    setDisplayLoader(false);

    if (stories.length === 0 || search !== "") {
      setStories(response.results);
    } else {
      setStories(stories.concat(response.results));
    }

    setCount(response.count);
  };

  const onChangeSelection = (e: any) => {
    setStories([]);
    setCurrentOffSet("");

    if (e.target.value == "Recent First") {
      setPostsOrdering("-published_date");
    }
    if (e.target.value == "Created First") {
      setPostsOrdering("id");
    }
  };

  const clearFunction = () => {
    setSearch("");
    setStories([]);
  };

  return (
    <>
      <CustomHelmet
        title={`Employees Success Stories | EA Foods`}
        metaDescription={`See what our employees have to say about East Africa Foods Co. Read EA foods employees’ success stories and reviews to know us better.`}
        imagePath={metaImage}
      />
      {displayLoader && <CustomLoader />}
      <StoryHeader
        heroTitle="Employees Success Stories"
        heroDesc="See What our Customers have to say... We are greatful to those customers who
                have been good enough to recommend us to friends."
      />
      <section className="success-stories-section section-padding">
        <Container>
          <Row className="g-0 justify-content-between">
            <Col md="4" lg="3">
              <InputGroup className="mb-10px">
                <Input
                  className="form-search"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentOffSet("");
                  }}
                />
                {search.length > 0 && (
                  <InputGroupText
                    className="bg-white border-start-0 cursor-pointer"
                    onClick={clearFunction}
                  >
                    <KTSVG path="/media/icons/ic_close.svg" className="ic" />
                  </InputGroupText>
                )}
              </InputGroup>
            </Col>
            <Col md="auto">
              <div className="d-flex sort-group align-items-center">
                <span className="w-56px text-muted fw-600">Sort by</span>
                <Input
                  type={"select"}
                  id="exampleSelect"
                  name="select"
                  onChange={onChangeSelection}
                >
                  <option value={"Recent First"}>Recent First</option>
                  <option value={"Created First"}>Created First</option>
                </Input>
              </div>
            </Col>
          </Row>
          {/* suceess stories list */}
          <Row>
            {stories.length === 0 ? (
              <div className="mt-5">{!displayLoader && <NoRecords />}</div>
            ) : (
              stories.map((item: any) => {
                return (
                  <>
                    <Col md="12" lg="6" className="mt-30px">
                      <a
                        href="JAVASCRIPT:void(0)"
                        className="text-dark cursor-pointer"
                        onClick={() => {
                          navigate(`/success-stories/${item.slug}`);
                        }}
                      >
                        <StoryCard
                          title={item.story_title}
                          name={item.firstname}
                          lastname={item.lastname}
                          subtitle={item.subtitle}
                          description={item.story_short_description}
                          category="Employee"
                          address={item.location_text}
                          date={dateFormat(item.publish_date).displayDate}
                          path={item.profile_picture}
                        />
                      </a>
                    </Col>
                  </>
                );
              })
            )}
            <Col xs="12" className="text-center mt-30px mb-3">
              {loadMore && stories.length > 0 && (
                <Button
                  color="primary"
                  outline
                  className="btn-rounded"
                  onClick={() => {
                    setCurrentOffSet(nextOffset(nextUrl));
                  }}
                >
                  Load More
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {/* <BlogSection /> */}
    </>
  );
};

export default MasterLayout(EmployeeStories);
