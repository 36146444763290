import React from "react";
import { Col, Container, Row } from "reactstrap";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import MasterLayout from "../../../layout/masterlayout";

const StoryFaustineKilumile = (props: any) => {
  return (
    <>
      <section className="bg-story-detail story-detail-banner pt-50px">
        <Container>
          <Row>
            <Col md="4" className="text-md-center">
              <img
                src={toAbsoluteUrl("/media/images/thumb_FaustineKilumile.png")}
                alt=""
              />
            </Col>
            <Col md="7">
              <div>
                <h1 className="mb-10px font-38">
                  I am so happy because they provide me with fair prices for
                  potatoes unlike the middlemen.
                </h1>
                <p className="mb-10px">
                  I urge my fellow farmers to join the groups organised by the
                  company for the training on good agricultural practices.
                </p>
                <p className="mb-10px">2nd Feb, 2023</p>
                <div>
                  <h3 className="mb-10px font-20">
                    Faustine Kilumile -{" "}
                    <span className="inter font-16 fw-400">Potato Farmer</span>
                  </h3>
                </div>
                <p className="mb-10px">Luhololo - Njombe</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* story content */}
      <section className="pt-50px pb-50px">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p className="fw-600">
                Faustine Kilumile is an Irish potato farmer in Njombe region,
                Tanzania, and has been working with EA Foods to supply Irish
                potatoes of the best quality for 3 years non-stop.
              </p>
              <p>
                Throughout the years of this business relationship, Faustine has
                received training on advanced and efficient agricultural
                practices which have helped him become more productive.
              </p>
              <p>
                Faustine has also received training in the demonstration farms
                as organised by EA Foods Farm and Co which have helped him to
                expand his agricultural activities and increased his yields.
              </p>
              <p className="fw-600">
                “I am so happy because they provide me with fair prices for
                potatoes unlike the middlemen, I now sell all my produce to EA
                Foods. I urge my fellow farmers to join the groups organised by
                the company for the training on good agricultural practices”.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-padding pt-0">
        <img
          src={toAbsoluteUrl("/media/images/faustineKilumile.jpg")}
          alt=""
          className="mb-30px img-fluid w-100"
        />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="story-content">
              <p className="mb-0">
                Faustine’s entrepreneurship journey has also skyrocketed in the
                past few years as he has opened a shop where he sells farming
                inputs for his fellow farmers and has plans of expanding land
                for his agricultural activities.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MasterLayout(StoryFaustineKilumile);
