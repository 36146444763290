import React, { Component } from "react"
import { toAbsoluteUrl } from "../helper/AssetHelpers"
import Footer from "./components/footer/footer"
import Header from "./components/header/header"
import SvgSprite from "./components/svgSprite/svgSprite"

const MasterLayout = (Component: any) => {
    return (props: any) => {
        return <>
            <div className="main-wrapper mdc-drawer-app-content body-wrapper">
                <div className="page-wrapper mdc-toolbar-fixed-adjust">
                    <Header />
                    <Component {...props} />
                    <Footer />
                    <SvgSprite />
                    <div>
                        <a href="https://wa.me/255672227777" target={"_blank"} className='whatsappchat'>
                            <img src={toAbsoluteUrl("/media/icons/ic_whatsapp.svg")}
                                alt="whatsapp" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    }
}

export default MasterLayout;
