import React, { useState } from "react"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"
import { toAbsoluteUrl } from "../../../helper/AssetHelpers"

type productCardProps = {
  title: string;
  description: string;
  className?: string;
  path: any;
  onClick?: any;
};
const ProductCard: React.FC<productCardProps> = ({
  title,
  description,
  path,
  className,
  onClick,
}) => {
  const [imageLoad, setImageLoad] = useState<boolean>(false)

  return (
    <>
      <Card
        className={`border-0 position-relative text-dark shadow-none p-1 h-100 bg-transparent ${className}`}
      >
        <div className="position-relative flex-grow-1">
          <img
            src={
              path ==
                "https://eaf-dms-api.yecor.com/upload/eaf-dms-api/default.jpg"
                ? toAbsoluteUrl("/media/images/img_placeholder.png")
                : toAbsoluteUrl(path)
            }
             onLoad={() => {
                 setImageLoad(true)
             }}
            alt=""
            className="rounded img-fluid"
          />
          {!imageLoad &&
            <span className="skeleton-box">
            </span>
          }
        </div>
        <CardBody className="px-0 pb-0">
          <CardTitle tag="h5" className="truncate-2 mb-10 product-title">
            {title}
          </CardTitle>
          <CardText className="truncate-2 mb-10">{description}</CardText>
        </CardBody>
      </Card>
    </>
  );
};

export default ProductCard;
